import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {Stack, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC} from 'react';
import {localized} from '../../../../i18n/i18n';
import {errorIconColor, succesIconColor} from '../../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notConfiguredIcon: {
      display: 'flex',
      justifyContent: 'left',
      marginLeft: 100,
      color: errorIconColor,
    },
    configuredIcon: {
      display: 'flex',
      justifyContent: 'left',
      marginLeft: 100,
      color: succesIconColor,
    },
    iconText: {
      margin: '0 0 0 20px',
    },
  }),
);

interface Props {
  isConfigured: boolean;
}

export const ConfigurationItemStatus: FC<Props> = React.memo((props) => {
  const classes = useStyles();

  return (
    <>
      {props.isConfigured ? (
        <Stack direction="row" className={classes.configuredIcon}>
          <CheckIcon></CheckIcon>
          <Typography variant="body2" className={classes.iconText}>
            {localized('Configured')}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" className={classes.notConfiguredIcon}>
          <ClearIcon></ClearIcon>
          <Typography variant="body2" className={classes.iconText}>
            {localized('NotConfigured')}
          </Typography>
        </Stack>
      )}
    </>
  );
});
