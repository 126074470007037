import {Box, TextField, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {ChangeEvent, FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Product, Project} from '../../../../api/api';
import {localized} from '../../../../i18n/i18n';
import {updateProject} from '../../../../state/features/project/operation';
import {updateProductInProject} from '../../../../state/features/project/project-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexBox: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
    textField: {
      margin: '0 20px 10px 0',
    },
  }),
);

interface Props {
  product: Product;
}

export const ConfigurationItemComment: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {project} = useSelector((store: AppState) => store.projectReducer);
  const configurationParam = props.product.configurationParams?.find(
    (parameter) => parameter.name === 'ProductComment',
  );

  const commentOnChange = useCallback(
    (text: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (configurationParam) {
        configurationParam.value = text.target.value;
        let updatedProduct = props.product;

        if (props.product.configurationParams) {
          let filteredConfigurationParams = props.product.configurationParams
            .slice()
            .filter((i) => i.name !== 'ProductComment');
          filteredConfigurationParams.push(configurationParam);
          updatedProduct.configurationParams = filteredConfigurationParams;
          dispatch(updateProductInProject(updatedProduct));
          dispatch(updateProject(project as Project));
        }
      }
    },
    [configurationParam, dispatch, project, props.product],
  );

  return (
    <Box className={classes.flexBox}>
      <Typography variant="body2">{localized('Remark')}</Typography>
      <TextField
        inputProps={{
          style: {
            fontSize: theme.typography.body3.fontSize,
          },
          disableOutline: true,
        }}
        multiline
        rows={3}
        className={classes.textField}
        defaultValue={configurationParam && configurationParam.value}
        onBlur={commentOnChange}
      />
    </Box>
  );
});
