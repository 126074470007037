import {numberOfTableFrameFittings} from '../../view/pages/products/configuration-constants';
import {findOne} from 'country-codes-list';

export const validCountryIds = {
  dk: 'dk',
  us: 'us',
  au: 'au',
};
export const validTranslationIds = {
  da: 'da',
  en: 'en',
  se: 'se',
};
export const validUnitsIds = {
  si: 'si',
  iu: 'iu',
};

export const recordToEntries = <T extends Record<string, unknown>>(record: T, isEnum = true) => {
  return Object.keys(record)
    .filter((entry) => !isEnum || isNaN(Number(entry)))
    .map((key: keyof typeof record) => ({
      key,
      value: record[key],
    }));
};

const validCountryIdsList = recordToEntries(validCountryIds, false).map((entry) => entry.value);
const validTranslationIdsList = recordToEntries(validTranslationIds, false).map((entry) => entry.value);
const validUnitsIdsList = recordToEntries(validUnitsIds, false).map((entry) => entry.value);

export const isTranslationIdValid = (id: string) => {
  return validTranslationIdsList.some((i) => i === id);
};
export const isCountryIdValid = (id: string) => {
  let countryCode = findOne('countryCode', id.toUpperCase())?.countryCode.toLowerCase() ?? 'dk';
  return countryCode;
};
export const isUnitsIdValid = (id: string) => {
  return validUnitsIdsList.some((i) => i === id);
};

export const getCountryCodeByName = (name: string) => {
  let countryCode = findOne('countryNameEn', name)?.countryCode.toLowerCase();
  return countryCode;
};

export const convertMmToInches = (value: string) => {
  if (value !== undefined) {
    return (Number(value) / 25.4).toFixed(2).toString();
  } else {
    return '';
  }
};

export const convertInchesToMm = (value: string) => {
  return (Number(value) * 25.4).toFixed(2).toString();
};
export const covertKiloToPounds = (value: string) => {
  return (Number(value) * 2.2).toFixed(0).toString();
};
export const covertPoundsToKilo = (value: string) => {
  return (Number(value) / 2.2).toFixed(0).toString();
};

export const convertMmToInchesRange = (value: string) => {
  var firstValue = value.substring(0, value.indexOf('-'));
  var secoundValue = value.substring(value.indexOf('-') + 1, value.indexOf(' '));
  return convertMmToInches(firstValue) + '-' + convertMmToInches(secoundValue) + ' inches';
};

export const stringOnlyContainsDigits = (text: string) => {
  return /^[0-9.,]+$/.test(text);
};

export const convertConfigurationLengthParamsIuToSi = (configurationParams: any) => {
  Object.keys(configurationParams).forEach(function (key) {
    if (key !== numberOfTableFrameFittings && stringOnlyContainsDigits(configurationParams[key] as string)) {
      configurationParams[key] = convertInchesToMm(configurationParams[key] as string);
    }
  });

  return configurationParams;
};

export const getUnitLengthNameByCulture = (unitsId: string, withSymbol?: boolean) => {
  let text = '';

  if (unitsId === 'iu') {
    if (withSymbol) {
      text = text.concat('” ');
    }
    text = text.concat('inches');
  } else {
    text = text.concat(' mm');
  }

  return text;
};
export const getUnitWeightNameByCulture = (unitsId: string) => {
  if (unitsId === 'iu') {
    return 'lb';
  } else {
    return 'kg';
  }
};

export const getUnitLenghtByCulture = (unitsId: string, value: string) => {
  if (unitsId === 'iu') {
    return convertMmToInches(value);
  } else {
    return value;
  }
};
export const getUnitWeightByCulture = (unitsId: string, value: string) => {
  if (unitsId === 'iu') {
    return covertKiloToPounds(value);
  } else {
    return value;
  }
};
export const getUnitRangebyCulture = (unitsId: string, value: string) => {
  if (unitsId === 'iu') {
    return convertMmToInchesRange(value);
  } else {
    return value;
  }
};
export const getValueLengthWithUnitByCulture = (unitsId: string, value: string) => {
  return getUnitLenghtByCulture(unitsId, value) + getUnitLengthNameByCulture(unitsId, true);
};
export const getValueWeighthWithUnitByCulture = (unitsId: string, value: string) => {
  return getUnitWeightByCulture(unitsId, value) + ' ' + getUnitWeightNameByCulture(unitsId);
};

export const getValueRangeWithUnitByCulture = (unitsId: string, value1: string, value2: string) => {
  return (
    getUnitLenghtByCulture(unitsId, value1) +
    '-' +
    getUnitLenghtByCulture(unitsId, value2) +
    getUnitLengthNameByCulture(unitsId, true)
  );
};
