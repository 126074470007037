import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './app';
import {Monitor} from './services/telemetry-service';
import history from './state/history';
import {InsightsKey} from './utilities/constants';

declare const window: any;

InsightsKey && Monitor.initialize(InsightsKey, history);

ReactDOM.render(<App />, document.getElementById('root'));
