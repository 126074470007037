import {Message, ValidationRule} from 'react-hook-form';
import {localized, localizedInterpolation} from '../../i18n/i18n';

export interface Validators {
  required?: Message | ValidationRule<boolean>;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
  maxLength?: ValidationRule<number>;
  minLength?: ValidationRule<number>;
  pattern?: ValidationRule<RegExp>;
  valueAsNumber?: boolean;
  valueAsDate?: boolean;
  setValueAs?: (value: any) => any;
  shouldUnregister?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
}

export const requiredValidator: Validators = {required: {value: true, message: localized('FieldIsRequired')}};
export function requiredValidatorDynamicMsg(msg: string) {
  let validator: Validators = {
    required: {value: true, message: msg},
  };
  return validator;
}
export const maxLength20Validator: Validators = {
  maxLength: {value: 20, message: localizedInterpolation('FieldHasMaxLength', {value: '20'})},
};
