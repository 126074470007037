import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ConfigurationParam, IProject, Product, Project} from '../../../api/api';
import {AppState} from '../../store';
import {deleteProject, getProjectByUrlId, saveProject, updateProject} from './operation';
import {ProjectState} from './types';

const initialState: ProjectState = {
  project: undefined,
  selectedProduct: undefined,
  allProductsConfigured: false,
  pending: false,
  isSuccess: false,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updateProjectInfo: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    addProductToProject: (state, action: PayloadAction<Product>) => {
      let newProducts: Product[] = [];
      let project: IProject;

      if (state.project) {
        if (state.project.products) {
          newProducts = state.project.products.slice();
          newProducts.push(action.payload);
        } else {
          newProducts.push(action.payload);
        }
        project = {...state.project, products: newProducts};
        state.project = project;
      }

      state.allProductsConfigured = false;
    },
    removeProductFromProject: (state, action: PayloadAction<Product>) => {
      let newProducts: Product[] = [];

      if (state.project && state.project.products) {
        const index = state.project.products.findIndex((product) => product.guid === action.payload.guid);

        if (index >= 0) {
          newProducts = [...state.project.products.slice(0, index), ...state.project.products.slice(index + 1)];
        }

        const project = {...state.project, products: newProducts};
        state.project = project;
        isAllProductsConfigured();
      }
    },
    updateProductInProject: (state, action: PayloadAction<Product>) => {
      if (state.project && state.project.products) {
        let temp = state.project.products.concat();
        let index = temp.findIndex((i) => i.guid === action.payload.guid);
        temp[index] = action.payload;

        const project = {...state.project, products: temp};
        state.project = project;
        isAllProductsConfigured();
      }
    },
    updateSomeProductsInProject: (state, action: PayloadAction<Product[]>) => {
      if (state.project && state.project.products) {
        action.payload.forEach((element) => {
          updateProductInProject(element);
        });
      }
      isAllProductsConfigured();
    },
    updateProductConfigurationParams: (state, action: PayloadAction<any>) => {
      if (state.selectedProduct) {
        let updatedProduct = state.selectedProduct;
        let tempConfigParams: ConfigurationParam[] = [];

        for (const key in action.payload) {
          let newConfig: ConfigurationParam = new ConfigurationParam();
          newConfig.name = key;
          newConfig.value = action.payload[key];

          tempConfigParams.push(newConfig);
        }

        updatedProduct.configurationParams = tempConfigParams;
        updatedProduct.configured = true;
        updateProductInProject(updatedProduct as Product);
      }
    },
    setSelectedProduct: (state, action: PayloadAction<Product>) => {
      if (action.payload) {
        state.selectedProduct = action.payload;
      }
    },
    addCommentToSelectedProduct: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.selectedProduct = {...state.selectedProduct, comments: action.payload} as Product;
      }
    },
    isAllProductsConfigured: (state) => {
      if (state.project && state.project.products) {
        state.allProductsConfigured = state.project.products.every((i) => i.configured === true);
      }
    },
    addCountryCalculationIdToProject: (state, action: PayloadAction<string>) => {
      if (state.project && action.payload) {
        state.project.countryCalculationId = action.payload.toUpperCase();
      }
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getProjectByUrlId.fulfilled, (state, action) => {
      state.pending = false;
      state.project = action.payload;
    });

    builder.addCase(getProjectByUrlId.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getProjectByUrlId.rejected, (state, action) => {
      state.pending = false;
    });

    // Save
    builder.addCase(saveProject.fulfilled, (state, action) => {
      state.pending = true;
      state.isSuccess = true;
      state.project = action.payload;
    });

    builder.addCase(saveProject.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(saveProject.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Update
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.pending = true;
      state.isSuccess = true;
      state.project = action.payload;
    });

    builder.addCase(updateProject.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateProject.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Delete
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state = initialState;
    });

    builder.addCase(deleteProject.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(deleteProject.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export const allProducts = (store: AppState) => {
  if (store.projectReducer.project) {
    return store.projectReducer.project.products;
  }
};

export const {
  updateProjectInfo,
  addProductToProject,
  removeProductFromProject,
  updateProductInProject,
  updateSomeProductsInProject,
  updateProductConfigurationParams,
  setSelectedProduct,
  isAllProductsConfigured,
  addCountryCalculationIdToProject,
} = projectsSlice.actions;

export default projectsSlice.reducer;
