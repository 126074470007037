import React, { FC, useEffect } from 'react';
import { BrandName } from '../../../../utilities/constants';

export interface PropsFromParent {
  title: string;
  shouldPrefixBrandName?: boolean;
}

export const PageTitle: FC<PropsFromParent> = React.memo((props) => {
  useEffect(() => {
    document.title =
      props.shouldPrefixBrandName !== false ? (BrandName ? `${BrandName} - ${props.title}` : props.title) : props.title;
  }, [props]);

  return <>{props.children}</>;
});
