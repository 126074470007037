import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import AB_Dim_420 from '../../../../assets/products/product-1/AB_Dim_420.jpg';
import {localized, localizedDynamic, localizedInterpolation} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';
import {
  getUnitLenghtByCulture,
  getUnitLengthNameByCulture,
  getValueLengthWithUnitByCulture,
  getValueWeighthWithUnitByCulture,
  validCountryIds,
} from '../../../../utilities/helpers/culture-settings-helper';
import {
  getConfigurationParamByName,
  getConfigurationParamByNameAndCulture,
} from '../../../../utilities/helpers/product-helper';
import {requiredValidatorDynamicMsg} from '../../../../utilities/platform-helpers/validator-helper';
import {ButtonInput} from '../../../components/custom-components/form/button-input';
import {TextInputLabel} from '../../../components/custom-components/form/text-input-label';
import {TextInput} from '../../../components/default-components/form/text-input';
import {
  liftingUnitStrength,
  tableColor,
  worktopDepth,
  worktopLength,
  tableTravel,
  tableUseCase,
} from '../configuration-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '686px',
      [theme.breakpoints.down('lg')]: {
        width: '500px',
      },
    },
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    formLabel: {
      display: 'flex',
      justifyContent: 'center',
    },
    formTextInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        border: '1px solid',
        borderColor: disabledButtonColor,
        borderRadius: 0,
      },
    },
    formBtnInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    unitText: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginLeft: 10,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: primaryColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: primaryColor,
      color: primaryTextColor,
    },
    input: {
      border: 'none',
      width: '200px',
      height: '50px',
    },
    gridItem: {
      height: '140px',
    },
  }),
);

export interface PropsFromParent {}

export const Product1Step1: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const [showPlacementWarning, setShowPlacmentWarning] = useState<boolean>(false);
  const cultureUrlParameters = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const {unitsId} = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const [maxTravel, setMaxTravel] = useState<string>(
    getConfigurationParamByName(selectedProduct, tableUseCase) == 'true' ? '300' : '600',
  );

  const useCaseBtnCallback = useCallback((value: boolean) => {
    setShowPlacmentWarning(!value);
    if (value) {
      setMaxTravel('600');
    } else {
      setMaxTravel('300');
    }
  }, []);

  const getTableTravelDefaultValue = () => {
    const defaultValue = getConfigurationParamByNameAndCulture(selectedProduct, tableTravel, unitsId);
    if (defaultValue == '' && unitsId == 'si') {
      return '300';
    } else if (defaultValue == '' && unitsId == 'iu') {
      return '11.81';
    } else {
      return defaultValue;
    }
  };

  const ImageRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <img src={AB_Dim_420}></img>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableLengthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={worktopLength}
            label={localized('LengthOfTheTableTop') + ' (A)'}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '400'),
                value2: getValueLengthWithUnitByCulture(unitsId, '3000'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct1Step1_1', {
              value1: getValueLengthWithUnitByCulture(unitsId, '400'),
              value2: getValueLengthWithUnitByCulture(unitsId, '3000'),
            })}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            <TextInput
              name={worktopLength}
              defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, worktopLength, unitsId)}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                min: {
                  value: Number(getUnitLenghtByCulture(unitsId, '400')),
                  message: 'Min. ' + getValueLengthWithUnitByCulture(unitsId, '400'),
                },
                max: {
                  value: Number(getUnitLenghtByCulture(unitsId, '3000')),
                  message: 'Max. ' + getValueLengthWithUnitByCulture(unitsId, '3000'),
                },
                pattern: {
                  value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                  message: localized('PleaseEnterANumber'),
                },
              }}
            />

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableDepthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={worktopDepth}
            label={localized('DepthOfTheTableTop') + ' (B)'}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '510'),
                value2: getValueLengthWithUnitByCulture(unitsId, '700'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct1Step1_2', {
              value1: getValueLengthWithUnitByCulture(unitsId, '510'),
              value2: getValueLengthWithUnitByCulture(unitsId, '700'),
            })}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            {
              <TextInput
                name={worktopDepth}
                defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, worktopDepth, unitsId)}
                validators={{
                  ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                  min: {
                    value: getUnitLenghtByCulture(unitsId, '510'),
                    message:
                      localizedDynamic('ValueMustBeOver') + ' ' + getValueLengthWithUnitByCulture(unitsId, '510'),
                  },
                  max: {
                    value: getUnitLenghtByCulture(unitsId, '700'),
                    message:
                      localizedDynamic('ValueMustBeUnder') + ' ' + getValueLengthWithUnitByCulture(unitsId, '700'),
                  },
                  pattern: {
                    value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                    message: localized('PleaseEnterANumber'),
                  },
                }}
              />
            }

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const LiftingUnitStrengthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={liftingUnitStrength}
            label={localized('LiftingUnitLiftStrengthIs')}
            tooltipLabel={localized('ToolTipProduct1Step1_3')}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formBtnInputBox}>
            <ButtonInput
              name={liftingUnitStrength}
              formFieldName={liftingUnitStrength}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              }}
              value1={'100 kg'}
              value2={'150 kg'}
              defaultValue={getConfigurationParamByName(selectedProduct, liftingUnitStrength)}
              btnLabel1={getValueWeighthWithUnitByCulture(unitsId, '100')}
              btnLabel2={getValueWeighthWithUnitByCulture(unitsId, '150')}
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableUseCaseRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={tableUseCase}
            label={localized('TabletopForWasingOrCooking')}
            tooltipLabel={localizedInterpolation('ToolTipProduct1Step1_4', {
              value1: getValueLengthWithUnitByCulture(unitsId, '70'),
              value2: getValueLengthWithUnitByCulture(unitsId, '500'),
            })}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formBtnInputBox}>
            <ButtonInput
              name={tableUseCase}
              formFieldName={tableUseCase}
              callBack={useCaseBtnCallback}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              }}
              value1={'true'}
              value2={'false'}
              defaultValue={getConfigurationParamByName(selectedProduct, tableUseCase)}
              btnLabel1={localized('Yes')}
              btnLabel2={localized('No')}
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const WarningRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body3" color="error">
          {localizedInterpolation('PlacementWarningX', {value1: getValueLengthWithUnitByCulture(unitsId, '70')})}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const TableHeigthRegulationRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={tableTravel}
            label={localized('WhatHeightAdjustmentIsDesired')}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '200'),
                value2: getValueLengthWithUnitByCulture(unitsId, maxTravel),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct1Step1_5', {
              value1: getValueLengthWithUnitByCulture(unitsId, '200'),
              value2: getValueLengthWithUnitByCulture(unitsId, '600'),
              value3: getValueLengthWithUnitByCulture(unitsId, '300'),
            })}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            <TextInput
              name={tableTravel}
              defaultValue={getTableTravelDefaultValue()}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                min: {
                  value: Number(getUnitLenghtByCulture(unitsId, '200')),
                  message: localizedDynamic('ValueMustBeOver') + ' ' + getValueLengthWithUnitByCulture(unitsId, '200'),
                },
                max: {
                  value: Number(getUnitLenghtByCulture(unitsId, maxTravel)),
                  message:
                    localizedDynamic('ValueMustBeUnder') + ' ' + getValueLengthWithUnitByCulture(unitsId, maxTravel),
                },
                pattern: {
                  value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                  message: localized('PleaseEnterANumber'),
                },
              }}
            />

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableColorRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel name={tableColor} label={localized('WhatLiftingUnitColor')} />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formBtnInputBox}>
            <ButtonInput
              name={tableColor}
              formFieldName={tableColor}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              }}
              value1="White"
              value2="Grey"
              defaultValue={getConfigurationParamByName(selectedProduct, tableColor)}
              btnLabel1={localized('White')}
              btnLabel2={localized('Grey')}
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid container spacing={6}>
            <ImageRow />
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableLengthRow />
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableDepthRow />
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <LiftingUnitStrengthRow />
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableUseCaseRow />
          </Grid>

          {showPlacementWarning ? (
            <Grid container spacing={6}>
              <WarningRow />
            </Grid>
          ) : (
            <></>
          )}

          <Grid container spacing={6} className={classes.gridItem}>
            <TableHeigthRegulationRow />
          </Grid>
          {cultureUrlParameters.contryId !== validCountryIds.us &&
            cultureUrlParameters.contryId !== validCountryIds.au && (
              <Grid container spacing={6} className={classes.gridItem}>
                <TableColorRow />
              </Grid>
            )}
        </Grid>
      </Box>
    </>
  );
});
