import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback} from 'react';
import {Product} from '../../../api/api';
import {localized, localizedDynamic} from '../../../i18n/i18n';
import {getSmallImgByProductType} from '../../../utilities/helpers/project-helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    showCursor: {
      cursor: 'pointer',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      margin: '20px 20px 20px 40px',
      height: 100,
    },
    text: {
      display: 'flex',
      justifyContent: 'left',
      margin: '60px 0 40px 35px',
    },
    row: {
      height: '150px',
      border: '1px solid #DCDCDC',
    },
    linkText: {
      display: 'flex',
      justifyContent: 'center',
      margin: '60px 0 40px 0',
    },
    link: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
      align: 'right',
    },
  }),
);

interface Props {
  product: Product;
  onDeleteClick?: (event: any) => void;
}
export const ProductItem: FC<Props> = React.memo((props) => {
  const classes = useStyles();

  const deletePressed = useCallback(() => {
    if (props.product && props.onDeleteClick) {
      props.onDeleteClick(props.product);
    }
  }, [props]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container direction="row" className={classes.row}>
          <Grid item xs={1}>
            <Box className={classes.image}>
              <img src={getSmallImgByProductType(props.product.productType)}></img>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box className={classes.text}>
              <Typography variant="body2">{props.product.name && localizedDynamic(props.product.name)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.linkText}>
              <Typography onClick={deletePressed} className={classes.link} variant="body2">
                {localized('Remove')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
