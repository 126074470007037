import {createAsyncThunk} from '@reduxjs/toolkit';
import {Organization, OrganizationClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {organizationClient} from '../../api-clients';

export const getOrganizations = createAsyncThunk('organizations/getOrganizations', (dispatch) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).get(), organizationClient);
});

export const getOrganizationById = createAsyncThunk('organizations/getOrganizationById', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).getById(organizationId), organizationClient);
});

export const saveOrganization = createAsyncThunk('organizations/saveOrganizations', (organization: Organization) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).post(organization), organizationClient, true);
});

export const updateOrganization = createAsyncThunk(
  'organizations/updateOrganizations',
  (organization: Organization) => {
    return AsyncOperationHandler(
      (client) => (client as OrganizationClient).put(organization),
      organizationClient,
      true,
    );
  },
);

export const deleteOrganization = createAsyncThunk(
  'organizations/deleteOrganizations',
  (organization: Organization) => {
    return AsyncOperationHandler((client) => (client as OrganizationClient).delete(organization), organizationClient);
  },
);
