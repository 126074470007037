import {KeyboardArrowDown} from '@mui/icons-material';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Theme, Typography} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC, memo, useMemo} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {theme} from '../../../../styles/theme';
import {DropdownOption} from './form-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: 200,
      marginRight: 36,
    },
  }),
);

interface Props {
  name: string;
  label?: string;
  options: DropdownOption[];
  defaultValue?: number;
  noDefault?: boolean;
  disabled?: boolean;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    disabled: boolean;
  }>;
}

export const SelectInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const classes = useStyles();

  const mapOptionsToItems = useMemo(
    () =>
      props.options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          <Typography variant="body1">{option.label}</Typography>
        </MenuItem>
      )),
    [props.options],
  );

  return props.options.length !== 0 ? (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      defaultValue={props.defaultValue}
      render={({field}) => (
        <FormControl sx={{marginTop: 1, marginBottom: 2}}>
          <InputLabel
            shrink={true}
            sx={{
              backgroundColor: theme.palette.common.white,
              paddingRight: theme.spacing(1),
              paddingLeft: theme.spacing(1),
              color: theme.palette.text.primary,
              marginLeft: '-6px',
            }}>
            {props.label}
          </InputLabel>
          <Select
            {...field}
            className={classes.select}
            disabled={props.disabled}
            error={!!errors[props.name]}
            value={field.value ?? (props.noDefault ? '' : props.options[0].value)}
            IconComponent={KeyboardArrowDown}>
            {mapOptionsToItems}
          </Select>
          {!!errors[props.name] && (
            <FormHelperText sx={{color: theme.palette.error.main}}>{errors[props.name].message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  ) : (
    <></>
  );
});
