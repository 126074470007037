import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

type PropertyCollection = { [name: string]: string };

// Research Error boundaries when it is supported by React hooks. For more information - https://reactjs.org/docs/error-boundaries.html
class TelemetryService {
  public readonly reactPlugin = new ReactPlugin();
  /**
   * Application insights. Exposes useful tracking utilities like
   * ```
   * trackPageView(),
   * trackPageViewPerformance(...),
   * trackEvent(), // Shows in "Usage" -> Events on Azure.
   * trackException()
   * ```
   */
  public appInsights?: ApplicationInsights;

  public initialize(insightsKey: string, appHistory: any) {
    if (this.appInsights) {
      console.warn('You cannot initialize the telemetry service if it already exists!');
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: insightsKey,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: {history: appHistory},
        },
      },
    });

    try {
      this.appInsights.loadAppInsights();
    } catch (error) {
      console.warn("Unable to load insights key. Are you sure it's valid?");
    }
  }

  public stopTelemetry() {
    this.appInsights = undefined;
  }

  public logException(exception: Error) {
    console.error(exception);
    this.appInsights && this.appInsights.trackException({exception: exception});
  }

  public logTrace(message: string, properties?: PropertyCollection) {
    console.warn(message);
    this.appInsights && this.appInsights.trackTrace({message}, properties);
  }
}

export const Monitor = new TelemetryService();
