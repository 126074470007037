import {Store} from 'redux';
import {AppState} from './store';

let store: Store<AppState>;
// Should only be called by the store file
export const setStore = (storeIn: Store<AppState>) => {
  store = storeIn;
};

// Only for use outside of components
export const getState = () => {
  return store.getState();
};

// Only for use outside of components
export const dispatchAction = (action: any) => {
  return store.dispatch(action);
};
