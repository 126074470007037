import {Box, Button, Grid, Stack, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, memo, useState} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {disabledButtonColor, primaryColor, primaryTextColor} from '../../../../styles/color-constants';
import placeholderImage from '../../../../assets/79x84.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      flexGrow: 1,
    },
    button: {
      '&:hover': {
        backgroundColor: disabledButtonColor,
      },
      width: '505px',
      height: '118px',
      padding: '0px 0px 0px 0px',
      borderRadius: '0px',
      border: '2px solid',
      borderBottom: '1px solid',
      borderBottomColor: disabledButtonColor,
      cursor: 'pointer',
      margin: '0 0 0 0',
      textAlign: 'center',
      color: primaryTextColor,
      textTransform: 'none',
      borderColor: disabledButtonColor,
      justifyContent: 'left',
    },
    buttonClicked: {
      backgroundColor: disabledButtonColor,
      border: '2px solid',
      borderColor: primaryColor,
    },
    image: {
      margin: '0 27px 0 0',
      display: 'flex',
      justifyContent: 'right',
    },
    text: {
      margin: '18px 0 0 36px',
    },
  }),
);

interface Props {
  name: string;
  disabledBtn1?: boolean;
  disabledBtn2?: boolean;
  value1: string | boolean;
  value2: string | boolean;
  images: string[];
  defaultValue?: string;
  select1Label: string;
  select2Label: string;
  formFieldName: string;
  callBack?: (event: boolean) => void;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const ListSelectInput: FC<Props> = memo((props) => {
  const {
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useFormContext();
  const classes = useStyles();
  const [btn1Disabled, setBtn1Disabled] = useState<boolean>(
    props.defaultValue === props.value1.toString() ?? getValues(props.formFieldName) === props.value1,
  );
  const [btn2Disabled, setBtn2Disabled] = useState<boolean>(
    props.defaultValue === props.value2.toString() ?? getValues(props.formFieldName) === props.value2,
  );

  const handleBtn1Click = () => {
    setBtn1Disabled(true);
    setBtn2Disabled(false);
    setValue(props.formFieldName ?? '', props.value1 ?? '');
  };

  const handleBtn2Click = () => {
    setBtn1Disabled(false);
    setBtn2Disabled(true);
    setValue(props.formFieldName ?? '', props.value2 ?? '');
  };

  const SelectOptionOne = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sx={{paddingTop: '0px'}}>
          <Button className={clsx(classes.button, {[classes.buttonClicked]: btn1Disabled})} onClick={handleBtn1Click}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack className={classes.text}>
                  <Typography align="left" variant="body3">
                    {props.select1Label}
                  </Typography>
                  <Typography align="left" variant="body1" fontWeight="bold">
                    {props.value1}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Box className={classes.image}>
                  <img src={props.images[0]}></img>
                </Box>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </React.Fragment>
    );
  };

  const SelectOptionTwo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Button className={clsx(classes.button, {[classes.buttonClicked]: btn2Disabled})} onClick={handleBtn2Click}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack className={classes.text}>
                  <Typography align="left" variant="body3">
                    {props.select2Label}
                  </Typography>
                  <Typography align="left" variant="body1" fontWeight="bold">
                    {props.value2}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Box className={classes.image}>
                  <img src={props.images[1]}></img>
                </Box>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      defaultValue={props.defaultValue}
      render={({field}) => (
        <Box sx={{flexGrow: 1, width: 505}}>
          <Grid container spacing={0}>
            {/* Select option 1 */}
            <Grid container item spacing={3}>
              <SelectOptionOne></SelectOptionOne>
            </Grid>
            {/* Slect option 2 */}
            <Grid container item spacing={3}>
              <SelectOptionTwo></SelectOptionTwo>
            </Grid>
          </Grid>
        </Box>
      )}
    />
  );
});
