// Theme colors
export const primaryColor = '#E66D2F';
export const primaryTextColor = '#2B2B2B';

export const secondaryColor = '#E7E7E7';
export const secondaryTextColor = '#FFFFFF';

export const backgroundColor = '#FFFFFF';
export const validColor = '#31FF00';
export const invalidColor = '#f44336';
export const warningColor = '#FDB900';
export const whiteColor = '#FFFFFF';
export const greyFadedColor = '#D6D6D6';

// Component colors

// Header
export const headerBackgroundColor = '#EEEEEE';

// Sidebar
export const sidebarBackgroundColor = '#F8F8F8';
export const sidebarIconColor = '#e95421';
export const sidebarBackgroundSelectedColor = '#ECECEC';
export const sidebarSelectedBorder = '#B8B9C8';
export const sideBarProfileBackgroundColor = '#f5f4f4';

// Content Wrapper

// Basic Card
export const basicCardBgColor = '#FFFFFF';
export const basicCardBoxShadow = '0px 3px 6px #00000029';
// Button
export const disabledButtonColor = '#DCDCDC';

// Input
export const requiredErrorColor = '#D32F2F';

// Toggle

// Switch

// Info item

// Textfield

// Checkbox Filter
export const checkboxFilterBg = '#FAFAFA';

// SearchInput
export const searchBgColor = '#E6E6E6';

// List Table

// Info item 2
export const infoItem2BackgroundColor = '#FAFAFA';

// Icons
export const errorIconColor = '#C11616';
export const succesIconColor = '#3F9B3F';
