import React from 'react';
import {ProductType} from '../../../api/api';
import {getLargeImgByProductType, getSmallImgByProductType} from '../../../utilities/helpers/project-helper';
import {Product1Step1} from './product-1/product-1-step-1';
import {Product1Step2} from './product-1/product-1-step-2';
import {Product1Step3} from './product-1/product-1-step-3';
import {Product2Step1} from './product-2/product-2-step-1';
import {Product2Step2} from './product-2/product-2-step-2';
import {Product3Step1} from './product-3/product-3-step-1';
import {Product3Step2} from './product-3/product-3-step-2';
import {Product3Step3} from './product-3/product-3-step-3';
import {Product4Step1} from './product-4/product-4-step-1';
import {Product4Step2} from './product-4/product-4-step-2';
import {Product5Step1} from './product-5/product-5-step-1';
import {Product5Step2} from './product-5/product-5-step-2';
import {ProductCommentStep} from './product-comment-step';

export interface ISteps {
  formName: string;
  forms: JSX.Element;
  defaultValues?: any;
}
export interface ProductSteps {
  type: ProductType;
  name: string;
  description: string;
  largeImg: string;
  smallImg: string;
  steps: ISteps[];
}

export const availableProducts: ProductSteps[] = [
  {
    type: ProductType.Table1,
    name: 'LiftingUnitForTableTopElectricHeightAdjustableWallMounted',
    description: 'LiftingUnitForTableTopElectricHeightAdjustableWallMounted',
    largeImg: getLargeImgByProductType(ProductType.Table1),
    smallImg: getSmallImgByProductType(ProductType.Table1),
    steps: [
      {
        formName: 'Tabeltop',
        forms: <Product1Step1 />,
      },
      {
        formName: 'Casket',
        forms: <Product1Step2 />,
      },
      {
        formName: 'SecurityShines',
        forms: <Product1Step3 />,
      },
      {
        formName: 'Remark',
        forms: <ProductCommentStep />,
      },
    ],
  },
  {
    type: ProductType.Table2,
    name: 'LiftingUnitForTableTopManualHeightAdjustableWallMounted',
    description: 'LiftingUnitForTableTopManualHeightAdjustableWallMounted',
    largeImg: getLargeImgByProductType(ProductType.Table2),
    smallImg: getSmallImgByProductType(ProductType.Table2),
    steps: [
      {
        formName: 'Tabeltop',
        forms: <Product2Step1 />,
      },
      {
        formName: 'Casket',
        forms: <Product2Step2 />,
      },
      {
        formName: 'Remark',
        forms: <ProductCommentStep />,
      },
    ],
  },
  {
    type: ProductType.Table3,
    name: 'LiftingUnitForTableTopElectricHeightAdjustable',
    description: 'LiftingUnitForTableTopElectricHeightAdjustable',
    largeImg: getLargeImgByProductType(ProductType.Table3),
    smallImg: getSmallImgByProductType(ProductType.Table3),
    steps: [
      {
        formName: 'Tabeltop',
        forms: <Product3Step1 />,
      },
      {
        formName: 'Casket',
        forms: <Product3Step2 />,
      },
      {
        formName: 'SecurityShines',
        forms: <Product3Step3 />,
      },
      {
        formName: 'Remark',
        forms: <ProductCommentStep />,
      },
    ],
  },
  {
    type: ProductType.Table4,
    name: 'LiftingUnitForTableTopManualHeightAdjustable',
    description: 'LiftingUnitForTableTopManualHeightAdjustable',
    largeImg: getLargeImgByProductType(ProductType.Table4),
    smallImg: getSmallImgByProductType(ProductType.Table4),
    steps: [
      {
        formName: 'Tabeltop',
        forms: <Product4Step1 />,
      },
      {
        formName: 'Casket',
        forms: <Product4Step2 />,
      },
      {
        formName: 'Remark',
        forms: <ProductCommentStep />,
      },
    ],
  },
  {
    type: ProductType.WallCabinet1,
    name: 'ElectricHeigthAdjustableLiftingUnitForWallCabinet',
    description: 'ElectricHeigthAdjustableLiftingUnitForWallCabinet',
    largeImg: getLargeImgByProductType(ProductType.WallCabinet1),
    smallImg: getSmallImgByProductType(ProductType.WallCabinet1),
    steps: [
      {
        formName: 'TypeOfLiftingUnit',
        forms: <Product5Step1 />,
      },
      {
        formName: 'SizeOfWallCabinet',
        forms: <Product5Step2 />,
      },
      {
        formName: 'Remark',
        forms: <ProductCommentStep />,
      },
    ],
  },
];
