import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { getPdfById } from './operation';
import { PdfState } from './types';

const initialState: PdfState = {
  fileResponse: {} as any,
  pending: false,
  isSuccess: false,
};

export const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getPdfById.fulfilled, (state, action) => {
      state.pending = false;
      state.fileResponse = action.payload!;
    });

    builder.addCase(getPdfById.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getPdfById.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export const selectPdf = (store: AppState) => store.pdfReducer.fileResponse;
// eslint-disable-next-line
export const {} = pdfSlice.actions;

export default pdfSlice.reducer;
