import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box, Tooltip, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles/createTheme';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, memo} from 'react';
import {
  greyFadedColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    infoIconDisabled: {
      color: greyFadedColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: greyFadedColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: greyFadedColor,
      color: primaryTextColor,
    },
    topMargin15: {
      marginTop: 15,
    },
    topMarginNegativ8: {
      marginTop: -8,
    },
  }),
);

interface Props {
  name: string;
  label: string;
  hintLabel?: string;
  tooltipLabel?: string;
  tooltipDisabled?: boolean;
  removeTopMargin?: boolean;
}

export const TextInputLabel: FC<Props> = memo((props) => {
  const classes = useStyles();

  return (
    <Box className={classes.formTextBox}>
      {props.tooltipLabel && (
        <Tooltip
          open={props.tooltipDisabled}
          title={props.tooltipLabel ?? ''}
          placement="top"
          arrow
          classes={{arrow: classes.arrow, tooltip: classes.tooltip}}>
          <InfoOutlinedIcon
            className={clsx([
              props.removeTopMargin ? classes.topMarginNegativ8 : classes.topMargin15,
              props.tooltipDisabled ? classes.infoIconDisabled : classes.infoIcon,
            ])}
          />
        </Tooltip>
      )}

      <Box>
        <Typography className={props.removeTopMargin ? classes.topMarginNegativ8 : classes.topMargin15} variant="body1">
          {props.label}
        </Typography>
        {props.hintLabel && (
          <Typography variant="body3" className={classes.hintText}>
            {props.hintLabel}
          </Typography>
        )}
      </Box>
    </Box>
  );
});
