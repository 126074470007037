import CloseIcon from '@mui/icons-material/Close';
import {Backdrop, Box, Button, Divider, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {localizedDynamic} from '../../../../i18n/i18n';
import {
  isAllProductsConfigured,
  updateProductConfigurationParams,
} from '../../../../state/features/project/project-slice';
import {AppState} from '../../../../state/store';
import {
  backgroundColor,
  disabledButtonColor,
  greyFadedColor,
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  whiteColor,
} from '../../../../styles/color-constants';
import {convertConfigurationLengthParamsIuToSi} from '../../../../utilities/helpers/culture-settings-helper';
import {availableProducts} from '../../../pages/products/products-list';
import {PrimaryButton} from '../../custom-components/primary-button';
import {Form} from '../form/form';
import {HorizontalStepper} from './stepper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold',
    },
    header: {
      borderWidth: 2,
      borderColor: greyFadedColor,
      borderStyle: 'solid',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    headerTextBox: {
      alignSelf: 'center',
    },
    headerText: {
      marginTop: 20,
      marginLeft: 30,
      alignSelf: 'center',
    },
    closeButtonBox: {
      borderLeft: 1,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0,
      borderColor: greyFadedColor,
      borderStyle: 'solid',
      height: 70,
      width: 70,
      alignSelf: 'center',
    },
    closeButton: {
      color: greyFadedColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      '&:hover': {
        color: primaryColor,
        opacity: 1,
      },
    },
    panel: {
      backgroundColor: backgroundColor,
      height: '100%',
      position: 'absolute',
    },
    panelPadding: {
      padding: 30,
    },
    divider: {
      marginTop: 30,
      marginBottom: 30,
    },
    slidePane: {
      overflow: 'auto',
      height: '100%',
      background: 'white',
      position: 'fixed',
      top: 0,
      right: 0,
      width: '50%',
      [theme.breakpoints.down('lg')]: {
        width: '90%',
      },
      zIndex: 200,
      boxShadow: '1px 0px 7px rgba(0,0,0,0.5)',
      transform: 'translateX(100%)',
      transition: 'transform 0.3s ease-out',
    },
    slidePaneOpen: {
      transform: 'translateX(0)',
    },
    selectedStepText: {
      fontWeight: 'bold',
      fontSize: 30,
    },
    stepAndStepperButtons: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    stepperButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    stepBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      padding: '30px 50px 30px 50px',
    },
    button: {
      '&:hover': {
        backgroundColor: whiteColor,
        color: primaryTextColor,
      },
    },
    buttonDisabled: {
      backgroundColor: disabledButtonColor,
      borderColor: disabledButtonColor,
      cursor: 'default',
    },
  }),
);

export interface PropsFromParent {
  openPanel: boolean;
  onClosePanelPressed(panelStatus: boolean): void;
}

export const SideMenuWizard: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cultureUrlParameters = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const [activeIndex, setActiveStep] = useState<number>(0);
  const [resetForms, setResetForms] = useState<boolean>(false);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const myRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if(myRef && myRef.current)
    {
      myRef.current.scrollTo(0, 0);
    }
  },[])
  const productSteps = useMemo(() => {
    return (
      availableProducts.find((productStep) => selectedProduct && productStep.type === selectedProduct.productType) ??
      availableProducts[0]
    );
  }, [selectedProduct]);

  const closePanelPressed = useCallback(() => {
    props.onClosePanelPressed(false);
    setActiveStep(0);
    setCompleted({});
    setResetForms(true);
    dispatch(isAllProductsConfigured());
  }, [dispatch, props]);

  const handleOnStepPressed = useCallback((index: number) => {
    setActiveStep(index);
  }, []);

  const onSubmit = useCallback(
    (data: any) => {
      let newCompleted = completed;
      newCompleted[activeIndex] = true;
      setCompleted(newCompleted);

      if (activeIndex === productSteps.steps.length - 1) {
        if (cultureUrlParameters.unitsId === 'iu') {
          data = convertConfigurationLengthParamsIuToSi(data);
        }

        dispatch(updateProductConfigurationParams(data));
        dispatch(isAllProductsConfigured());

        props.onClosePanelPressed(false);
        setResetForms(true);
        setActiveStep(0);
        setCompleted({});
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    },
    [activeIndex, completed, cultureUrlParameters.unitsId, dispatch, productSteps.steps.length, props],
  );

  const stepperButtons = (
    <Box className={classes.stepperButtons}>
      {activeIndex !== productSteps.steps.length && (
        <Button type="submit" sx={{textTransform: 'none'}}>
          <PrimaryButton className={clsx(classes.button)} bgcolor={primaryColor} color={secondaryTextColor}>
            <Typography variant="body1">{localizedDynamic('SaveAndContinue')}</Typography>
          </PrimaryButton>
        </Button>
      )}
    </Box>
  );

  return (
    <Backdrop open={props.openPanel}>
      {props.openPanel && (
        <div className={clsx([classes.slidePane, props.openPanel ? classes.slidePaneOpen : null])}>
          <Box ref={myRef} className={classes.header}>
            <Box className={classes.headerTextBox}>
              <Typography display={'inline'} className={clsx([classes.bold, classes.headerText])}>
                {localizedDynamic('Configures') + ' '}
              </Typography>
              <Typography display={'inline'}>{localizedDynamic(productSteps.name)}</Typography>
            </Box>
            <Box className={classes.closeButtonBox} onClick={closePanelPressed}>
              <CloseIcon className={classes.closeButton} />
            </Box>
          </Box>
          <div className={classes.panelPadding}>
            {selectedProduct && (
              <Form onSubmit={onSubmit} resetValues={resetForms}>
                <HorizontalStepper
                  activeIndex={activeIndex}
                  stepCount={productSteps.steps.length}
                  completedSteps={completed}
                  onStepPressedCallback={handleOnStepPressed}
                />

                <Box className={classes.stepAndStepperButtons}>
                  <Typography className={classes.selectedStepText}>
                    {activeIndex + 1}. {localizedDynamic(productSteps.steps[activeIndex].formName)}
                  </Typography>
                  {stepperButtons}
                </Box>
                <Divider className={classes.divider} />
                <Box className={classes.stepBox}>{productSteps.steps[activeIndex].forms}</Box>
                <Divider className={classes.divider} />
                <Box>{stepperButtons}</Box>
              </Form>
            )}
          </div>
        </div>
      )}
    </Backdrop>
  );
});
