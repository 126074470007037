import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {changeLanguage} from '../../../i18n/i18n';
import {
  isCountryIdValid,
  isTranslationIdValid,
  isUnitsIdValid,
  validTranslationIds,
} from '../../../utilities/helpers/culture-settings-helper';
import {AppState} from '../../store';
import {urlSettingsState, cultureUrlParameters as CultureUrlParameters} from './types';

const initialState: urlSettingsState = {
  cultureUrlParameters: {
    translationId: 'da',
    contryId: 'dk',
    unitsId: 'si',
  },
  projectUrlId: '',
};
export const urlSettingsSlice = createSlice({
  name: 'urlSettingsSlice',
  initialState,
  reducers: {
    setCultureUrlSettings: (state, action: PayloadAction<CultureUrlParameters>) => {
      if (isTranslationIdValid(action.payload.translationId)) {
        switch (action.payload.translationId) {
          case validTranslationIds.da:
            changeLanguage('da');
            break;
          case validTranslationIds.en:
            changeLanguage('en');
            break;
          case validTranslationIds.se:
            changeLanguage('se');
            break;

          default:
            changeLanguage('da');
            break;
        }
        state.cultureUrlParameters.translationId = action.payload.translationId;
      }
      if (isUnitsIdValid(action.payload.unitsId)) {
        state.cultureUrlParameters.unitsId = action.payload.unitsId;
      }
      if (isCountryIdValid(action.payload.contryId)) {
        state.cultureUrlParameters.contryId = action.payload.contryId;
      }
    },
    setProjectUrlId: (state, action: PayloadAction<string>) => {
      state.projectUrlId = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const getCultureUrlParameters = (store: AppState) => {
  if (store.urlSettingsReducer.cultureUrlParameters) {
    return store.urlSettingsReducer.cultureUrlParameters;
  } else {
    return initialState.cultureUrlParameters;
  }
};
export const getProjectUrlId = (store: AppState) => {
  if (store.urlSettingsReducer.projectUrlId) {
    return '/' + store.urlSettingsReducer.projectUrlId;
  } else {
    return '/' + initialState.projectUrlId;
  }
};

export const getLocalAndUnitsParameter = (store: AppState) => {
  if (store.urlSettingsReducer.cultureUrlParameters) {
    return (
      '/' +
      store.urlSettingsReducer.cultureUrlParameters.translationId +
      '-' +
      store.urlSettingsReducer.cultureUrlParameters.contryId +
      '/' +
      store.urlSettingsReducer.cultureUrlParameters.unitsId
    );
  } else {
    return '/' + initialState.cultureUrlParameters.translationId + '/' + initialState.cultureUrlParameters.unitsId;
  }
};

export const getCombinedUrlParameters = (store: AppState) => {
  if (store.urlSettingsReducer.cultureUrlParameters) {
    return (
      '/' +
      store.urlSettingsReducer.cultureUrlParameters.translationId +
      '-' +
      store.urlSettingsReducer.cultureUrlParameters.contryId +
      '/' +
      store.urlSettingsReducer.cultureUrlParameters.unitsId +
      '/' +
      store.urlSettingsReducer.projectUrlId
    );
  } else {
    return (
      '/' +
      initialState.cultureUrlParameters.translationId +
      '-' +
      initialState.cultureUrlParameters.contryId +
      '/' +
      initialState.cultureUrlParameters.unitsId +
      '/' +
      initialState.projectUrlId
    );
  }
};
// eslint-disable-next-line no-empty-pattern
export const {setCultureUrlSettings, setProjectUrlId} = urlSettingsSlice.actions;
export default urlSettingsSlice.reducer;
