import {createTheme} from '@mui/material/styles';
import {SimplePaletteColorOptions} from '@mui/material/styles/createPalette';
import {CSSProperties} from 'react';
import {
  basicCardBoxShadow,
  checkboxFilterBg,
  headerBackgroundColor,
  infoItem2BackgroundColor,
  primaryColor,
  primaryTextColor,
  searchBgColor,
  secondaryColor,
  secondaryTextColor,
  sidebarBackgroundColor,
  sidebarBackgroundSelectedColor,
  sideBarProfileBackgroundColor,
  sidebarSelectedBorder,
  whiteColor,
} from './color-constants';
import {DefaultPalette} from './color-palette';

declare module '@mui/material/styles' {
  interface Palette {
    Example: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    Example?: SimplePaletteColorOptions;
  }

  interface TypographyVariants {
    body3: CSSProperties;
    body4: CSSProperties;
    body5: CSSProperties;
  }
  interface Theme {
    colors: {
      header: {
        headerBackgroundColor: CSSProperties['color'];
        headerIconColor: CSSProperties['color'];
        headerTextColor: CSSProperties['color'];
        headerSelectedTextColor: CSSProperties['color'];
        headerSelectedIconColor: CSSProperties['color'];
      };
      sideBar: {
        sidebarBackgroundColor: CSSProperties['color'];
        sidebarIconColor: CSSProperties['color'];
        sidebarTextColor: CSSProperties['color'];
        sidebarBackgroundSelectedColor: CSSProperties['color'];
        sidebarSelectedBorder: CSSProperties['color'];
        sideBarProfileBackgroundColor: CSSProperties['color'];
      };
      contentWrapper: {
        contentWrapperBackgroundColor: CSSProperties['color'];
        contentWrapperTextColor: CSSProperties['color'];
      };
      basicCard: {
        basicCardBgColor: CSSProperties['color'];
        basicCardBoxShadow: CSSProperties['boxShadow'];
        basicCardTextColor: CSSProperties['color'];
      };
      button: {
        btnBackgroundColor: CSSProperties['color'];
        btnTextColor: CSSProperties['color'];
      };
      inputTextColor: CSSProperties['color'];
      toggle: {
        toggleButtonColor: CSSProperties['color'];
        toggleButtonColorSelected: CSSProperties['color'];
        toggleButtonTextColor: CSSProperties['color'];
      };
      switch: {
        switchThumbOnColor: CSSProperties['color'];
        switchThumbOffColor: CSSProperties['color'];
        switchTrackOnColor: CSSProperties['color'];
        switchTrackOffColor: CSSProperties['color'];
        switchBorderOnColor: CSSProperties['color'];
        switchBorderOffColor: CSSProperties['color'];
      };
      infoItem: {
        infoItemBackgroundColorPrimary: CSSProperties['color'];
        infoItemBackgroundColorSecondary: CSSProperties['color'];
        infoItemTextColor: CSSProperties['color'];
      };
      textField: {
        textFieldInputColor: CSSProperties['color'];
        textFieldLabelColor: CSSProperties['color'];
        textFieldSuffixColor: CSSProperties['color'];
        textFieldPrefixColor: CSSProperties['color'];
      };
      checkBoxFilter: {
        checkboxFilterBg: CSSProperties['color'];
        checkboxChecked: CSSProperties['color'];
        checkBoxUnChecked: CSSProperties['color'];
      };
      searchInput: {
        searchBgColor: CSSProperties['color'];
        searchTextColor: CSSProperties['color'];
      };
      listTable: {
        ListTableBgColor: CSSProperties['color'];
        ListTableTextColor: CSSProperties['color'];
      };
      danger: CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    colors: {
      header?: {
        headerBackgroundColor?: CSSProperties['color'];
        headerIconColor?: CSSProperties['color'];
        headerTextColor?: CSSProperties['color'];
        headerSelectedTextColor?: CSSProperties['color'];
        headerSelectedIconColor?: CSSProperties['color'];
      };
      sideBar?: {
        sidebarBackgroundColor?: CSSProperties['color'];
        sidebarIconColor?: CSSProperties['color'];
        sidebarTextColor?: CSSProperties['color'];
        sidebarBackgroundSelectedColor?: CSSProperties['color'];
        sidebarSelectedBorder?: CSSProperties['color'];
        sideBarProfileBackgroundColor?: CSSProperties['color'];
      };
      contentWrapper?: {
        contentWrapperBackgroundColor?: CSSProperties['color'];
        contentWrapperTextColor?: CSSProperties['color'];
      };
      basicCard?: {
        basicCardBgColor?: CSSProperties['color'];
        basicCardBoxShadow?: CSSProperties['boxShadow'];
        basicCardTextColor?: CSSProperties['color'];
      };
      button?: {
        btnBackgroundColor?: CSSProperties['color'];
        btnTextColor?: CSSProperties['color'];
      };
      inputTextColor?: CSSProperties['color'];
      toggle?: {
        toggleButtonColor?: CSSProperties['color'];
        toggleButtonColorSelected?: CSSProperties['color'];
        toggleButtonTextColor?: CSSProperties['color'];
      };
      switch?: {
        switchThumbOnColor?: CSSProperties['color'];
        switchThumbOffColor?: CSSProperties['color'];
        switchTrackOnColor?: CSSProperties['color'];
        switchTrackOffColor?: CSSProperties['color'];
        switchBorderOnColor?: CSSProperties['color'];
        switchBorderOffColor?: CSSProperties['color'];
      };
      infoItem?: {
        infoItemBackgroundColorPrimary?: CSSProperties['color'];
        infoItemBackgroundColorSecondary?: CSSProperties['color'];
        infoItemTextColor?: CSSProperties['color'];
      };
      textField?: {
        textFieldInputColor?: CSSProperties['color'];
        textFieldLabelColor?: CSSProperties['color'];
        textFieldSuffixColor?: CSSProperties['color'];
        textFieldPrefixColor?: CSSProperties['color'];
      };
      checkBoxFilter?: {
        checkboxFilterBg?: CSSProperties['color'];
        checkboxChecked?: CSSProperties['color'];
        checkBoxUnChecked?: CSSProperties['color'];
      };
      searchInput?: {
        searchBgColor?: CSSProperties['color'];
        searchTextColor?: CSSProperties['color'];
      };
      listTable?: {
        ListTableBgColor?: CSSProperties['color'];
        ListTableTextColor?: CSSProperties['color'];
      };
      danger?: CSSProperties['color'];
    };
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: CSSProperties;
    body4?: CSSProperties;
    body5?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
  }
}

export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
          fontFamily: 'Segoe UI',
          fontStyle: 'italic'
        }
      }
    }
  },
  colors: {
    header: {
      headerBackgroundColor: headerBackgroundColor,
      headerIconColor: primaryTextColor,
      headerTextColor: primaryTextColor,
      headerSelectedTextColor: primaryColor,
      headerSelectedIconColor: primaryColor,
    },
    sideBar: {
      sidebarBackgroundColor: sidebarBackgroundColor,
      sidebarIconColor: primaryColor,
      sidebarTextColor: secondaryTextColor,
      sidebarBackgroundSelectedColor: sidebarBackgroundSelectedColor,
      sidebarSelectedBorder: sidebarSelectedBorder,
      sideBarProfileBackgroundColor: sideBarProfileBackgroundColor
    },
    contentWrapper: {
      contentWrapperBackgroundColor: secondaryColor,
      contentWrapperTextColor: secondaryTextColor,
    },
    basicCard: {
      basicCardBgColor: whiteColor,
      basicCardBoxShadow: basicCardBoxShadow,
      basicCardTextColor: secondaryTextColor,
    },
    button: {
      btnBackgroundColor: primaryColor,
      btnTextColor: primaryTextColor,
    },
    inputTextColor: secondaryTextColor,
    toggle: {
      toggleButtonColor: primaryTextColor,
      toggleButtonColorSelected: primaryColor,
      toggleButtonTextColor: whiteColor,
    },
    switch: {
      switchThumbOnColor: whiteColor,
      switchThumbOffColor: primaryTextColor,
      switchTrackOnColor: primaryColor,
      switchTrackOffColor: whiteColor,
      switchBorderOnColor: primaryColor,
      switchBorderOffColor: primaryTextColor,
    },
    infoItem: {
      infoItemBackgroundColorPrimary: secondaryColor,
      infoItemBackgroundColorSecondary: infoItem2BackgroundColor,
      infoItemTextColor: secondaryTextColor,
    },
    textField: {
      textFieldInputColor: secondaryTextColor,
      textFieldLabelColor: primaryTextColor,
      textFieldSuffixColor: primaryTextColor,
      textFieldPrefixColor: primaryTextColor,
    },
    checkBoxFilter: {
      checkboxFilterBg: checkboxFilterBg,
      checkboxChecked: primaryColor,
      checkBoxUnChecked: primaryTextColor,
    },
    searchInput: {
      searchBgColor: searchBgColor,
      searchTextColor: secondaryTextColor,
    },
    listTable: {
      ListTableBgColor: secondaryColor,
      ListTableTextColor: secondaryTextColor,
    },
  },
  palette: DefaultPalette,
  typography: {
    fontFamily: ['Segoe UI'].join(','),
    fontSize: 14,
    h1: {
      fontSize: 82,
      fontWeight: 'bold'
    },
    h2: {
      fontSize: 40,
      fontWeight: 'bold'
    },
    h3: {
      fontSize: 30,
      fontWeight: 'bold'
    },
    h4: {
      fontSize: 21,
      fontWeight: 'bold'
    },
    body1: {
      fontSize: 16
    },
    body2: {
      fontSize: 14,
      fontWeight: 'bold'
    }, 
    body3: {
      fontSize: 14,
      color: "#737373",
      fontWeight: 'normal',
      fontStyle: 'italic'
    },
    body4: {
      fontSize: 10,
      color: "#737373",
      fontWeight: 'normal',
      fontStyle: 'italic'
    },
    body5: {
      fontSize: 10,
      fontWeight: 'normal',
    },
  
  },
});
