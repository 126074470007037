import {createAsyncThunk} from '@reduxjs/toolkit';
import {Project, ProjectClient} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {projectClient} from '../../api-clients';
import {dispatchAction} from '../../store-container';
import {showErrorSnackbar} from '../snackbar/snackbar-slice';
export const getProjectByUrlId = createAsyncThunk('projects/getProjectById', async (projectUrlId: string) => {
  const {validate} = require('uuid');

  if (validate(projectUrlId)) {
    let response = await AsyncOperationHandler(
      (client) => (client as ProjectClient).getByUrlId(projectUrlId),
      projectClient,
    );
    if (response) {
      return response;
    } else {
      dispatchAction(showErrorSnackbar(localized('CouldNotFindASavedProjectWithThisId')));
      return response;
    }
  } else {
    dispatchAction(showErrorSnackbar(localized('TheIdDoesNotFollowTheFormatting')));
  }
});

export const saveProject = createAsyncThunk('projects/saveProject', (project: Project) => {
  return AsyncOperationHandler((client) => (client as ProjectClient).post(project), projectClient, true);
});

export const updateProject = createAsyncThunk('projects/updateProject', (project: Project) => {
  return AsyncOperationHandler((client) => (client as ProjectClient).put(project), projectClient, true);
});

export const deleteProject = createAsyncThunk('projects/deleteProject', (project: Project) => {
  return AsyncOperationHandler((client) => (client as ProjectClient).delete(project), projectClient);
});
