import { StepButton, StepConnector, stepConnectorClasses, styled, Theme } from '@mui/material';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { primaryColor } from '../../../../styles/color-constants';
const QontoConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: primaryColor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: primaryColor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperBox: {
      width: '50%',
      marginBottom: 50,
    },
  }),
);

export interface PropsFromParent {
  activeIndex: number;
  stepCount: number;
  completedSteps: {[k: number]: boolean};
  onStepPressedCallback(index: number): void;
}

export const HorizontalStepper: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();
  const [steps, setSteps] = React.useState<JSX.Element[]>();
  const handleStep = React.useCallback(
    (step: number) => () => {
      props.onStepPressedCallback(step);
    },
    [props],
  );

  useEffect(() => {
    let temp = [];
    for (let index = 0; index < props.stepCount; index++) {
      temp.push(
        <Step key={index} completed={props.completedSteps[index]}>
          <StepButton
            disableTouchRipple={props.completedSteps[index]}
            onClick={handleStep(index)}></StepButton>
        </Step>,
      );
    }
    setSteps(temp);
  }, [handleStep, props]);

  return (
    <>
      <Stepper activeStep={props.activeIndex} className={classes.stepperBox} connector={<QontoConnector />}>
        {steps}
      </Stepper>
    </>
  );
});
