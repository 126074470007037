import {Box, SxProps, Theme, TypographyProps} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC} from 'react';
import {primaryTextColor, whiteColor} from '../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 255,
      height: 50,
      border: '3px solid #E66D2F',
      cursor: 'pointer',
    },
  }),
);

interface Props extends TypographyProps {
  sx?: SxProps<Theme>;
  onClick?: (event: any) => void;
}
export const PrimaryButton: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  return (
    <Box
      sx={props.sx}
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      onClick={props.onClick}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={whiteColor}
      color={primaryTextColor}
      {...props}
      className={clsx(classes.root, props.className)}>
      {props.children}
    </Box>
  );
});