import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, memo, useEffect, useMemo, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {IProduct} from '../../../../api/api';
import maskGroupImg from '../../../../assets/products/product-5/MaskGroup5.png';
import {localized, localizedDynamic, localizedInterpolation} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';
import {
  getUnitLenghtByCulture,
  getUnitLengthNameByCulture,
  getValueLengthWithUnitByCulture,
} from '../../../../utilities/helpers/culture-settings-helper';
import {getConfigurationParamByNameAndCulture} from '../../../../utilities/helpers/product-helper';
import {requiredValidatorDynamicMsg} from '../../../../utilities/platform-helpers/validator-helper';
import {TextInputLabel} from '../../../components/custom-components/form/text-input-label';
import {TextInput} from '../../../components/default-components/form/text-input';
import {wallCupboardDepth, wallCupboardHeight, wallCupboardLength} from '../configuration-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '686px',
      [theme.breakpoints.down('lg')]: {
        width: '500px',
      },
    },
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    formLabel: {
      display: 'flex',
      justifyContent: 'center',
    },
    formTextInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        border: '1px solid',
        borderColor: disabledButtonColor,
        borderRadius: 0,
      },
    },
    formBtnInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    unitText: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginLeft: 10,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: primaryColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: primaryColor,
      color: primaryTextColor,
    },
    gridItem: {
      height: '120px',
    },
  }),
);

interface Props {
  unitsId: string;
  product?: IProduct;
  max?: string;
  min?: string;
}

const LengthOfWallCabinetRow: FC<Props> = memo((props) => {
  const classes = useStyles();
  const defaultValue = useMemo(
    () => getConfigurationParamByNameAndCulture(props.product, wallCupboardLength, props.unitsId),
    [props.product, props.unitsId],
  );

  return (
    <React.Fragment>
      <Grid item xs={7}>
        <TextInputLabel
          name={wallCupboardLength}
          label={localized('LengthOfWallCabinet') + ' (E)'}
          tooltipLabel={localized('ToolTipProduct5Step2_1')}
          hintLabel={
            '(' +
            localizedInterpolation('BetweenXandY', {
              value1: getValueLengthWithUnitByCulture(props.unitsId, props.min ? props.min : ''),
              value2: getValueLengthWithUnitByCulture(props.unitsId, props.max ? props.max : ''),
            }) +
            ')'
          }></TextInputLabel>
      </Grid>
      <Grid item xs={5}>
        <Box className={classes.formTextInputBox}>
          <TextInput
            name={wallCupboardLength}
            defaultValue={defaultValue}
            validators={{
              ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              min: {
                value: Number(getUnitLenghtByCulture(props.unitsId, props.min ? props.min : '')),
                message: 'Min. ' + getValueLengthWithUnitByCulture(props.unitsId, props.min ? props.min : ''),
              },
              max: {
                value: Number(getUnitLenghtByCulture(props.unitsId, props.max ? props.max : '')),
                message: 'Max. ' + getValueLengthWithUnitByCulture(props.unitsId, props.max ? props.max : ''),
              },
              pattern: {
                value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                message: localized('PleaseEnterANumber'),
              },
            }}
          />

          <Typography className={classes.unitText}>{getUnitLengthNameByCulture(props.unitsId)}</Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
});

const HeigthOfWallCabinetRow: FC<Props> = memo((props) => {
  const classes = useStyles();
  const defaultValue = useMemo(
    () => getConfigurationParamByNameAndCulture(props.product, wallCupboardHeight, props.unitsId),
    [props.product, props.unitsId],
  );

  return (
    <React.Fragment>
      <Grid item xs={7}>
        <TextInputLabel
          name={wallCupboardHeight}
          label={localized('HeigthOfWallCabinet') + ' (F)'}
          tooltipLabel={localizedInterpolation('ToolTipProduct5Step2_2', {
            value1: getValueLengthWithUnitByCulture(props.unitsId, '576'),
            value2: getValueLengthWithUnitByCulture(props.unitsId, '729'),
            value3: getValueLengthWithUnitByCulture(props.unitsId, '730'),
            value4: getValueLengthWithUnitByCulture(props.unitsId, '920'),
          })}
          hintLabel={
            '(' +
            localizedInterpolation('BetweenXandY', {
              value1: getValueLengthWithUnitByCulture(props.unitsId, '576'),
              value2: getValueLengthWithUnitByCulture(props.unitsId, '924'),
            }) +
            ')'
          }></TextInputLabel>
      </Grid>
      <Grid item xs={5}>
        <Box className={classes.formTextInputBox}>
          <TextInput
            name={wallCupboardHeight}
            defaultValue={defaultValue}
            validators={{
              ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              min: {
                value: Number(getUnitLenghtByCulture(props.unitsId, '576')),
                message: 'Min. ' + getValueLengthWithUnitByCulture(props.unitsId, '576'),
              },
              max: {
                value: Number(getUnitLenghtByCulture(props.unitsId, '924')),
                message: 'Max. ' + getValueLengthWithUnitByCulture(props.unitsId, '924'),
              },
              pattern: {
                value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                message: localized('PleaseEnterANumber'),
              },
            }}></TextInput>
          <Typography className={classes.unitText}>{getUnitLengthNameByCulture(props.unitsId)}</Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
});

const DepthOfWallCabinetRow: FC<Props> = memo((props) => {
  const classes = useStyles();
  const defaultValue = useMemo(
    () => getConfigurationParamByNameAndCulture(props.product, wallCupboardDepth, props.unitsId),
    [props.product, props.unitsId],
  );

  return (
    <React.Fragment>
      <Grid item xs={7}>
        <TextInputLabel
          name={wallCupboardDepth}
          label={localized('DepthOfWallCabinet') + ' (G)'}
          hintLabel={
            '(' +
            localizedInterpolation('MaxX', {
              value1: getValueLengthWithUnitByCulture(props.unitsId, '350'),
            }) +
            ')'
          }
          tooltipLabel={localizedInterpolation('ToolTipProduct5Step2_3', {
            value1: getValueLengthWithUnitByCulture(props.unitsId, '350'),
          })}></TextInputLabel>
      </Grid>
      <Grid item xs={5}>
        <Box className={classes.formTextInputBox}>
          <TextInput
            name={wallCupboardDepth}
            defaultValue={defaultValue}
            validators={{
              ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              max: {
                value: Number(getUnitLenghtByCulture(props.unitsId, '350')),
                message:
                  localizedDynamic('ValueMustBeUnder') + ' ' + getValueLengthWithUnitByCulture(props.unitsId, '350'),
              },
              pattern: {
                value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                message: localized('PleaseEnterANumber'),
              },
            }}></TextInput>
          <Typography className={classes.unitText}>{getUnitLengthNameByCulture(props.unitsId)}</Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
});

export interface PropsFromParent {}

export const Product5Step2: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const {unitsId} = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const {getValues} = useFormContext();
  const myRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({block: 'end'});
    }
  }, []);

  const getMinValue = () => {
    const type = getValues('LiftingUnitType');
    if (type == '400-1200 mm') {
      return '400';
    } else if (type == '1201-2400 mm') {
      return '1202';
    } else if (type == '15.75-47.24” inches') {
      return '400';
    } else if (type == '47.28-94.49” inches') {
      return '1202';
    } else {
      return '0';
    }
  };

  const getMaxValue = () => {
    const type = getValues('LiftingUnitType');
    if (type == '400-1200 mm') {
      return '1200';
    } else if (type == '1201-2400 mm') {
      return '2400';
    } else if (type == '15.75-47.24” inches') {
      return '1200';
    } else if (type == '47.28-94.49” inches') {
      return '2400';
    } else {
      return '0';
    }
  };

  const getCurrentHeight = (): number => {
    return getValues(wallCupboardHeight);
  };

  const ImageRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <img src={maskGroupImg}></img>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const ExtenderNotificationRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body3" color="error">
            {localized('RememberExtender')}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid ref={myRef} container item spacing={6}>
            <ImageRow></ImageRow>
          </Grid>

          <Grid container item spacing={6}>
            <LengthOfWallCabinetRow
              unitsId={unitsId}
              product={selectedProduct}
              max={getMaxValue()}
              min={getMinValue()}></LengthOfWallCabinetRow>
          </Grid>

          <Grid container item spacing={6}>
            <HeigthOfWallCabinetRow unitsId={unitsId} product={selectedProduct}></HeigthOfWallCabinetRow>
          </Grid>

          {getCurrentHeight() > 728 && getCurrentHeight() < 925 && (
            <Grid container item spacing={6}>
              <ExtenderNotificationRow></ExtenderNotificationRow>
            </Grid>
          )}

          <Grid container item spacing={6}>
            <DepthOfWallCabinetRow unitsId={unitsId} product={selectedProduct}></DepthOfWallCabinetRow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
