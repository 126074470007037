export const worktopLength = 'WorktopLength';
export const worktopDepth = 'WorktopDepth';
export const tableTravel = 'TableTravel';
export const wallCupboardLength = 'WallCupboardLength';
export const wallCupboardDepth = 'WallCupboardDepth';
export const wallCupboardHeight = 'WallCupboardHeight';
export const hasFascia = 'HasFascia';
export const frameLength = 'FrameLength';
export const frameDepth = 'FrameDepth';
export const hasRightRail = 'HasRightRail';
export const hasLeftRail = 'HasLeftRail';
export const hasFrontRail = 'HasFrontRail';
export const hasBackRail = 'HasBackRail';
export const liftingUnitStrength = 'LiftingUnitStrength';
export const tableUseCase = 'TableUseCase';
export const tableColor = 'Color';
export const numberOfTableFrameFittings = 'NumberOfTableFrameFittings'