import {Breadcrumbs, Theme, Typography} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
  }),
);

export interface Breadcrumb {
  routeName: string;
  label: string;
}

interface Props {
  breadcrumbs: Breadcrumb[];
}
export const BasicBreadcrumbs: FC<Props> = React.memo((props) => {
  const classes = useStyles();

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon color="secondary" fontSize="small" />}>
        {props.breadcrumbs?.map((breadcrumb, index) => {
          const last = index === props.breadcrumbs.length - 1;
          return last ? (
            <Typography variant="body3" key={breadcrumb.routeName}>
              {breadcrumb.label}
            </Typography>
          ) : (
            <Link to={breadcrumb.routeName} key={breadcrumb.routeName} className={classes.link}>
              <Typography variant="body3">{breadcrumb.label}</Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
});
