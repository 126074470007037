import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import product3IllustrationImg from '../../../../assets/products/product-3/product3Illustration.png';
import {localized, localizedDynamic, localizedInterpolation} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';
import {
  getUnitLenghtByCulture,
  getUnitLengthNameByCulture,
  getValueLengthWithUnitByCulture,
} from '../../../../utilities/helpers/culture-settings-helper';
import {getConfigurationParamByNameAndCulture} from '../../../../utilities/helpers/product-helper';
import {requiredValidatorDynamicMsg} from '../../../../utilities/platform-helpers/validator-helper';
import {TextInputLabel} from '../../../components/custom-components/form/text-input-label';
import {TextInput} from '../../../components/default-components/form/text-input';
import {tableTravel, worktopDepth, worktopLength} from '../configuration-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '686px',
      [theme.breakpoints.down('lg')]: {
        width: '500px',
      },
    },
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    formLabel: {
      display: 'flex',
      justifyContent: 'center',
    },
    formTextInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        border: '1px solid',
        borderColor: disabledButtonColor,
        borderRadius: 0,
      },
    },
    formBtnInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    unitText: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginLeft: 10,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: primaryColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: primaryColor,
      color: primaryTextColor,
    },
    gridItem: {
      height: '140px',
    },
  }),
);

export interface PropsFromParent {}

export const Product3Step1: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const {unitsId} = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const [showPlacementWarning, setShowPlacementWarning] = useState<boolean>(false);

  const getTableTravelDefaultValue = () => {
    const defaultValue = getConfigurationParamByNameAndCulture(selectedProduct, tableTravel, unitsId);
    if (defaultValue == '' && unitsId == 'si') {
      return '300';
    } else if (defaultValue == '' && unitsId == 'iu') {
      return '11.81';
    } else {
      return defaultValue;
    }
  };

  const useCaseBtnCallback = useCallback((value: boolean) => {
    setShowPlacementWarning(!value);
  }, []);

  const ImageRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" height={420}>
            <img src={product3IllustrationImg}></img>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableLengthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={worktopLength}
            label={localized('LengthOfTheTableTop') + ' (A)'}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '1400'),
                value2: getValueLengthWithUnitByCulture(unitsId, '3000'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct3Step1_1', {
              value1: getValueLengthWithUnitByCulture(unitsId, '1400'),
              value2: getValueLengthWithUnitByCulture(unitsId, '3000'),
            })}></TextInputLabel>
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            <TextInput
              name={worktopLength}
              defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, worktopLength, unitsId)}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                min: {
                  value: Number(getUnitLenghtByCulture(unitsId, '1400')),
                  message: getValueLengthWithUnitByCulture(unitsId, '1400'),
                },
                max: {
                  value: Number(getUnitLenghtByCulture(unitsId, '3000')),
                  message: getValueLengthWithUnitByCulture(unitsId, '3000'),
                },
                pattern: {
                  value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                  message: localized('PleaseEnterANumber'),
                },
              }}></TextInput>

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableDepthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={worktopDepth}
            label={localized('DepthOfTheTableTop') + ' (B)'}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '700'),
                value2: getValueLengthWithUnitByCulture(unitsId, '1000'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct3Step1_2', {
              value1: getValueLengthWithUnitByCulture(unitsId, '700'),
              value2: getValueLengthWithUnitByCulture(unitsId, '1000'),
            })}></TextInputLabel>
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            <TextInput
              name={worktopDepth}
              defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, worktopDepth, unitsId)}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                min: {
                  value: Number(getUnitLenghtByCulture(unitsId, '700')),
                  message: localizedDynamic('ValueMustBeOver') + ' ' + getValueLengthWithUnitByCulture(unitsId, '700'),
                },
                max: {
                  value: Number(getUnitLenghtByCulture(unitsId, '1000')),
                  message:
                    localizedDynamic('ValueMustBeUnder') + ' ' + getValueLengthWithUnitByCulture(unitsId, '1000'),
                },
                pattern: {
                  value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                  message: localized('PleaseEnterANumber'),
                },
              }}></TextInput>

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const WarningRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body3" color="error">
            {localizedInterpolation('PlacementWarningX', {value1: getValueLengthWithUnitByCulture(unitsId, '70')})}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const TableHeigthRegulationRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={tableTravel}
            label={localized('WhatHeightAdjustmentIsDesired')}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '200'),
                value2: getValueLengthWithUnitByCulture(unitsId, '300'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct2Step1_4', {
              value1: getValueLengthWithUnitByCulture(unitsId, '200'),
              value2: getValueLengthWithUnitByCulture(unitsId, '300'),
            })}></TextInputLabel>
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            <TextInput
              name={tableTravel}
              defaultValue={getTableTravelDefaultValue()}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                min: {
                  value: Number(getUnitLenghtByCulture(unitsId, '200')),
                  message: localizedDynamic('ValueMustBeOver') + ' ' + getValueLengthWithUnitByCulture(unitsId, '200'),
                },
                max: {
                  value: Number(getUnitLenghtByCulture(unitsId, '300')),
                  message: localizedDynamic('ValueMustBeUnder') + ' ' + getValueLengthWithUnitByCulture(unitsId, '300'),
                },
                pattern: {
                  value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                  message: localized('PleaseEnterANumber'),
                },
              }}></TextInput>

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid container spacing={6}>
            <ImageRow></ImageRow>
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableLengthRow></TableLengthRow>
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableDepthRow></TableDepthRow>
          </Grid>

          {showPlacementWarning ? (
            <Grid container spacing={6} className={classes.gridItem}>
              <WarningRow></WarningRow>
            </Grid>
          ) : (
            <></>
          )}

          <Grid container spacing={6} className={classes.gridItem}>
            <TableHeigthRegulationRow></TableHeigthRegulationRow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
