import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import oneCabinet from '../../../../assets/products/product-5/skab_2-1.png';
import twoCabinets from '../../../../assets/products/product-5/skab_2.png';
import {localized} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {getValueRangeWithUnitByCulture} from '../../../../utilities/helpers/culture-settings-helper';
import {ListSelectInput} from '../../../components/custom-components/form/list-select-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '500px',
    },
    text: {
      margin: '0 0 20px 0 ',
    },
  }),
);

export interface PropsFromParent {}
export const Product5Step1: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const {unitsId} = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);

  const TextRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box className={classes.text}>
            <Typography variant="body1">{localized('ChooseTypeOfLiftingUnitForYourFit')}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const listImages: string[] = [oneCabinet, twoCabinets];

  const TypeOfLiftingUnitRow = () => {
    const defaultValue = useMemo(
      () => selectedProduct?.configurationParams?.find((i) => i.name === 'LiftingUnitType')?.value ?? undefined,
      [],
    );

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box>
            <ListSelectInput
              name="LiftingUnitType"
              defaultValue={defaultValue}
              value1={getValueRangeWithUnitByCulture(unitsId, '400', '1200')}
              value2={getValueRangeWithUnitByCulture(unitsId, '1201', '2400')}
              select1Label={localized('ForWallCabinetWithLength')}
              select2Label={localized('ForWallCabinetWithLength')}
              formFieldName="LiftingUnitType"
              images={listImages}></ListSelectInput>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={0} className={classes.grid}>
          <Grid container item spacing={6}>
            <TextRow></TextRow>
          </Grid>

          <Grid container item spacing={6}>
            <TypeOfLiftingUnitRow></TypeOfLiftingUnitRow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
