import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import HVF_Dim_DK from '../../../../assets/products/product-1/INDIVO_AB_Dim_DK.png';
import HVF_Dim_ENG from '../../../../assets/products/product-1/INDIVO_AB_Dim_ENG.png';
import {localized} from '../../../../i18n/i18n';
import {getCultureUrlParameters} from '../../../../state/features/urlSettings/url-settings-slice';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  greyFadedColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';
import {validCountryIds} from '../../../../utilities/helpers/culture-settings-helper';
import {getConfigurationParamByName} from '../../../../utilities/helpers/product-helper';
import {requiredValidatorDynamicMsg} from '../../../../utilities/platform-helpers/validator-helper';
import {ButtonInput} from '../../../components/custom-components/form/button-input';
import {TextInputLabel} from '../../../components/custom-components/form/text-input-label';
import {hasFrontRail, hasLeftRail, hasRightRail} from '../configuration-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '506px',
    },
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    formInputBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    unitText: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginLeft: 10,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: greyFadedColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: greyFadedColor,
      color: primaryTextColor,
    },
    list: {
      display: 'list-item',
    },
    button: {
      width: '79px',
      heigth: '42px',
      margin: ' 0 35px 0 10px',
      textAlign: 'center',
      color: primaryTextColor,
      font: 'normal normal normal 16px/21px Segoe UI',
      border: '3px solid',
      borderColor: disabledButtonColor,
      borderRadius: '0px',
    },
    buttonClicked: {
      borderColor: greyFadedColor,
    },
    select: {
      width: '61px',
    },
    gridItem: {
      height: '120px',
    },
  }),
);

export interface PropsFromParent {}

export const Product1Step3: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const urlParam = useSelector(getCultureUrlParameters);
  const myRef = useRef<null | HTMLDivElement>(null);

  const getSafetyRailDefault = useCallback(
    (railType: string) => {
      return (
        getConfigurationParamByName(selectedProduct, railType) ??
        (urlParam.contryId === validCountryIds.us ? 'true' : '')
      );
    },
    [selectedProduct, urlParam.contryId],
  );

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({block: 'end'});
    }
  }, []);

  const IntroRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1">{localized('PressalitSecurityShinesSuggestion')}</Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const ImageRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <img style={{maxWidth: 500}} src={urlParam.translationId === 'da' ? HVF_Dim_DK : HVF_Dim_ENG}></img>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const FrontShineRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextInputLabel
            name={hasFrontRail}
            label={localized('HasFrontShine')}
            tooltipLabel={localized('ToolTipProduct1Step3_1')}></TextInputLabel>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <ButtonInput
              name={hasFrontRail}
              formFieldName={hasFrontRail}
              validators={{...requiredValidatorDynamicMsg(localized('FieldIsRequired'))}}
              value1={'true'}
              value2={'false'}
              defaultValue={getSafetyRailDefault(hasFrontRail)}
              btnLabel1={localized('Yes')}
              btnLabel2={localized('No')}></ButtonInput>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const LeftShineRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextInputLabel
            name={hasLeftRail}
            label={localized('HasLeftShine')}
            tooltipLabel={localized('ToolTipProduct1Step3_2')}></TextInputLabel>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <ButtonInput
              name={hasLeftRail}
              formFieldName={hasLeftRail}
              validators={{...requiredValidatorDynamicMsg(localized('FieldIsRequired'))}}
              value1={'true'}
              value2={'false'}
              defaultValue={getSafetyRailDefault(hasLeftRail)}
              btnLabel1={localized('Yes')}
              btnLabel2={localized('No')}></ButtonInput>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const RigthShineRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextInputLabel
            name={hasRightRail}
            label={localized('HasRightShine')}
            tooltipLabel={localized('ToolTipProduct1Step3_3')}></TextInputLabel>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <ButtonInput
              name={hasRightRail}
              formFieldName={hasRightRail}
              validators={{...requiredValidatorDynamicMsg(localized('FieldIsRequired'))}}
              value1={'true'}
              value2={'false'}
              defaultValue={getSafetyRailDefault(hasRightRail)}
              btnLabel1={localized('Yes')}
              btnLabel2={localized('No')}></ButtonInput>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2.5} className={classes.grid}>
          <Grid ref={myRef} container spacing={3} className={classes.gridItem}>
            <IntroRow></IntroRow>
          </Grid>

          <Grid container spacing={3}>
            <ImageRow></ImageRow>
          </Grid>

          <Grid container spacing={3} className={classes.gridItem}>
            <FrontShineRow></FrontShineRow>
          </Grid>

          <Grid container spacing={3} className={classes.gridItem}>
            <LeftShineRow></LeftShineRow>
          </Grid>

          <Grid container spacing={3} className={classes.gridItem}>
            <RigthShineRow></RigthShineRow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
