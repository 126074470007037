import {Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback} from 'react';
import {Product} from '../../../../api/api';
import {localized} from '../../../../i18n/i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      justifyContent: 'left',
      marginLeft: 100,
      align: 'right',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

interface Props {
  product: Product;
  isConfigured: boolean;
  startConfiguration: (product: Product) => void;
  editConfiguration: (product: Product) => void;
}

export const ConfigurationItemStart: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const onStartConfigurationPressed = useCallback(() => {
    props.startConfiguration(props.product);
  }, [props]);
  const onEditConfigurationPressed = useCallback(() => {
    props.editConfiguration(props.product);
  }, [props]);

  return (
    <>
      {props.isConfigured ? (
        <Typography onClick={onEditConfigurationPressed} className={classes.link} variant="body2">
          {localized('EditConfiguration')}
        </Typography>
      ) : (
        <Typography onClick={onStartConfigurationPressed} className={classes.link} variant="body2">
          {localized('StartConfiguration')}
        </Typography>
      )}
    </>
  );
});
