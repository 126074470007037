import {Box, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ControlUnit, Product, ProductType} from '../../../../api/api';
import {localized, localizedDynamic} from '../../../../i18n/i18n';
import {isAllProductsConfigured, updateProductInProject} from '../../../../state/features/project/project-slice';
import {
  allTableContacts,
  allWallCabinetContacts,
  ControlUnitInfo,
  getControlUnitInfoByEnum,
} from '../../../../utilities/helpers/configuration-contacts-helper';
import {ConfigurationButtonItem} from './configuration-button-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemListBox: {
      display: 'flex',
    },
    groupHeader: {
      marginTop: 20,
    },
  }),
);

interface Props {
  products: Product[];
}
export const ConfigurationButtonSelector: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialTableControlUnit = props.products.find(
    (i) =>
      i.productType != ProductType.Table2 &&
      i.productType != ProductType.Table4 &&
      i.productType != ProductType.WallCabinet1,
  )?.controlUnit;

  const initialWallCabinetControlUnit =
    props.products.filter((i) => i.productType > ProductType.Table4 && i.controlUnit != ControlUnit.NotDefined).at(-1)
      ?.controlUnit ?? ControlUnit.NotDefined;
  const initialWallCabinets = props.products.filter((i) => i.productType === ProductType.WallCabinet1);
  const [selectedTableControlUnit, setSelectedTableControlUnit] = useState<ControlUnit>(initialTableControlUnit!);
  const [selectedWallCabinetControlUnit, setSelectedWallCabinetControlUnit] = useState<ControlUnit>(
    initialWallCabinetControlUnit!,
  );
  const [filterControlUnit, setFilterControlUnit] = useState<ControlUnit>();
  const [wallCabinetsToUpdate, setWallCabinetsToUpdate] = useState<Product[]>(
    props.products.concat().filter((i) => i.productType == ProductType.WallCabinet1),
  );

  const updateControlUnitOnProducts = useCallback(
    (products: Product[], controlUnit: ControlUnit) => {
      products.map((product: Product, index) => {
        product.controlUnit = controlUnit;
      });

      products.forEach((element) => {
        dispatch(updateProductInProject(element));
      });
    },
    [dispatch],
  );

  const wiredHandControlSelected = useCallback(
    (choosenControlUnit: ControlUnit) => {
      if (choosenControlUnit === ControlUnit.ManualOperation) {
        let numberOfSingleWallCabinets = initialWallCabinets.length;
        let numberOfElectricTables = props.products.filter(
          (i) =>
            i.productType != ProductType.Table2 &&
            i.productType != ProductType.Table4 &&
            i.productType != ProductType.WallCabinet1,
        ).length;

        updateControlUnitOnProducts(initialWallCabinets.slice(), ControlUnit.NotDefined);

        let missingControlUnints = numberOfSingleWallCabinets - numberOfElectricTables * 2;
        if (missingControlUnints > 0) {
          setWallCabinetsToUpdate(wallCabinetsToUpdate.slice(-missingControlUnints));
        } else {
          setWallCabinetsToUpdate([]);
        }
      } else {
        setWallCabinetsToUpdate(props.products.concat().filter((i) => i.productType == ProductType.WallCabinet1));
      }
    },
    [initialWallCabinets, props.products, updateControlUnitOnProducts, wallCabinetsToUpdate],
  );

  const wallCabinetControlUnitPressed = useCallback(
    (controlUnit: ControlUnitInfo) => {
      updateControlUnitOnProducts(wallCabinetsToUpdate.slice(), controlUnit.type);
      setSelectedWallCabinetControlUnit(controlUnit.type);
    },
    [updateControlUnitOnProducts, wallCabinetsToUpdate],
  );

  const tableControlUnitPressed = useCallback(
    (controlUnit: ControlUnitInfo) => {
      let temp = props.products.concat();
      temp
        .filter(
          (i) =>
            i.productType != ProductType.Table2 &&
            i.productType != ProductType.Table4 &&
            i.productType != ProductType.WallCabinet1,
        )
        .map((product: Product, index) => {
          product.controlUnit = controlUnit.type;
        });

      temp.forEach((element) => {
        dispatch(updateProductInProject(element));
      });

      setSelectedTableControlUnit(controlUnit.type);

      switch (controlUnit.type) {
        case ControlUnit.OvalContactAlu:
          setWallCabinetsToUpdate(initialWallCabinets);
          setFilterControlUnit(ControlUnit.RoundContactAlu);
          break;

        case ControlUnit.ManualOperation:
          wiredHandControlSelected(controlUnit.type);
          setFilterControlUnit(ControlUnit.OvalContactRed);
          break;

        default:
          setWallCabinetsToUpdate(initialWallCabinets);
          setFilterControlUnit(ControlUnit.OvalContactRed);
          break;
      }

      setSelectedWallCabinetControlUnit(ControlUnit.NotDefined);
    },
    [dispatch, initialWallCabinets, props.products, wiredHandControlSelected],
  );

  useEffect(() => {
    if (initialTableControlUnit) {
      tableControlUnitPressed(getControlUnitInfoByEnum(initialTableControlUnit));
    }
    if (initialWallCabinetControlUnit) {
      wallCabinetControlUnitPressed(getControlUnitInfoByEnum(initialWallCabinetControlUnit));
    }
    dispatch(isAllProductsConfigured());
  }, []);

  return (
    <Box>
      {props.products.some(
        (i) =>
          i.productType != ProductType.Table2 &&
          i.productType != ProductType.Table4 &&
          i.productType != ProductType.WallCabinet1,
      ) && (
        <>
          <Typography variant="body2">{localized('BasedOnYourConfiguration')}</Typography>
          <Typography variant="h4" className={classes.groupHeader}>
            {localizedDynamic('ChooseOneOfTheOptionsForOperatingFixedTables')}
          </Typography>
          <Box className={classes.itemListBox}>
            {allTableContacts.map((controlUnits, index) => {
              return (
                <ConfigurationButtonItem
                  key={index}
                  controlUnits={controlUnits}
                  onPress={tableControlUnitPressed}
                  selected={selectedTableControlUnit === controlUnits.type}
                />
              );
            })}
          </Box>
        </>
      )}

      {wallCabinetsToUpdate.length > 0 && (
        <>
          <Typography variant="h4" className={classes.groupHeader}>
            {localizedDynamic('ChooseOneOfTheOptionsForOperatingFixedWallCabinets')}
          </Typography>
          <Box className={classes.itemListBox}>
            {allWallCabinetContacts
              .filter((i) => i.type !== filterControlUnit)
              .map((controlUnits, index) => {
                return (
                  <ConfigurationButtonItem
                    key={index}
                    controlUnits={controlUnits}
                    onPress={wallCabinetControlUnitPressed}
                    selected={selectedWallCabinetControlUnit === controlUnits.type}
                  />
                );
              })}
          </Box>
        </>
      )}
    </Box>
  );
});
