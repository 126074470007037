import {Box, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, useCallback} from 'react';
import {Product} from '../../../../api/api';
import {localizedDynamic} from '../../../../i18n/i18n';
import {disabledButtonColor} from '../../../../styles/color-constants';
import {ControlUnitInfo} from '../../../../utilities/helpers/configuration-contacts-helper';
import {BasicCard} from '../../default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonItem: {
      textAlign: 'center',
      position: 'unset',
      border: '1px solid #DCDCDC',
      width: 200,
      height: 240,
      boxShadow: 'none',
      margin: '24px 2px 24px 2px',
      padding: '0px',
      opacity: 1,
      '&:hover': {
        boxShadow: '2px 2px 10px lightgrey',
        cursor: 'pointer',
      },
    },
    buttonItemSelected: {
      border: '3px solid #E66D2F',
      margin: '20px 0px 20px 0px',
      backgroundColor: disabledButtonColor,
    },
    img: {
      display: 'flex',
      justifyContent: 'center',
      height: 100,
      margin: '40px 40px 10px 40px',
    },
    buttonProductName: {
      marginBottom: 10,
    },
  }),
);

interface Props {
  controlUnits: ControlUnitInfo;
  selected: boolean;
  onPress: (contact: ControlUnitInfo) => void;
}
export const ConfigurationButtonItem: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const contactPressed = useCallback(() => {
    props.onPress(props.controlUnits);
  }, [props]);

  return (
    <BasicCard
      className={clsx(classes.buttonItem, {
        [classes.buttonItemSelected]: props.selected,
      })}
      onClick={contactPressed}>
      <Box className={classes.img}>
        <img src={props.controlUnits.imgLarge}></img>
      </Box>
      <Typography variant="body3">{props.controlUnits.id}</Typography>
      <Typography variant="body1" className={classes.buttonProductName}>
        {localizedDynamic(props.controlUnits.name)}
      </Typography>
    </BasicCard>
  );
});
