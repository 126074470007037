import {AppBar, Box, Theme, Toolbar} from '@mui/material';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, Navigate, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom';
import Logo from './assets/logo_black.png';
import {useAuth} from './auth/auth-provider';
import {Callback} from './auth/callback';
import {getCombinedUrlParameters} from './state/features/urlSettings/url-settings-slice';
import {projectId, routes, localsAndUnitsParameters} from './state/routes';
import {AppState} from './state/store';
import {OverlaySpinner} from './view/components/default-components/spinner/overlay-spinner';
import {Configuration} from './view/pages/configuration/configuration';
import {Produktkonfigurator} from './view/pages/home/home';
import {Products} from './view/pages/products/products';
import {Summary} from './view/pages/summary/summary';

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileOnly: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    desktopOnly: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    appContent: {flex: 1},
    logo: {
      alignItems: 'left',
      margin: '0 auto 0 auto',
      width: 1400,
    },
  }),
);

export const AppRoutes: FC = React.memo(() => {
  const classes = useLayoutStyles();
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  // URL parameter for language and units setting.
  // Gets by useParams(); and .[Id]
  // Saves in store.
  // Remember always to include them in the router.
  const navigate = useNavigate();
  const urlParameters = useSelector(getCombinedUrlParameters);
  const projectUrlId = useSelector((store: AppState) => store.projectReducer.project?.urlParam);
  const params = useParams();

  useEffect(() => {
    // VERY IMPORTANT TO NOT NAVIGATE BEFORE USER IS AUTHENTICATED
    if (location.pathname === '/' && isAuthenticated) {
      navigate(routes.home + urlParameters);
    }
  }, [location.pathname, navigate, isAuthenticated, urlParameters, projectUrlId, params.projectId]);

  return (
    <div>
      <Box sx={{flexGrow: 1}}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Link className={classes.logo} to={routes.home + urlParameters}>
              <img src={Logo} alt="Logo" />
            </Link>
          </Toolbar>
        </AppBar>
      </Box>

      <Grid wrap="nowrap" container={true}>
        <OverlaySpinner />
        <div className={classes.appContent}>
          <Routes>
            <Route path="/">
              <Route index element={<Produktkonfigurator />} />
              <Route path={routes.home + localsAndUnitsParameters} element={<Produktkonfigurator />} />
              <Route path={routes.home + localsAndUnitsParameters + projectId} element={<Produktkonfigurator />} />
              <Route path={routes.productChooser + localsAndUnitsParameters} element={<Products />} />
              <Route path={routes.productChooser + localsAndUnitsParameters + projectId} element={<Products />} />
              <Route path={routes.configuration + localsAndUnitsParameters} element={<Configuration />} />
              <Route
                path={routes.configuration + localsAndUnitsParameters + projectId}
                element={<Configuration />}
              />
              <Route path={routes.summary + localsAndUnitsParameters} element={<Summary />} />
              <Route path={routes.summary + localsAndUnitsParameters + projectId} element={<Summary />} />
            </Route>
            <Route path={'*'} element={<Navigate to={routes.home + urlParameters} />} />
            <Route path={routes.callback} element={<Callback />} />
          </Routes>
        </div>
      </Grid>
    </div>
  );
});
