import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Product} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {getLocalAndUnitsParameter} from '../../../state/features/urlSettings/url-settings-slice';
import {routes} from '../../../state/routes';
import {primaryColor, primaryTextColor, secondaryTextColor, whiteColor} from '../../../styles/color-constants';
import {PrimaryButton} from './primary-button';
import {ProductItem} from './product-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftButton: {
      '&:hover': {
        backgroundColor: primaryColor,
        color: secondaryTextColor,
      },
      margin: '0 19px 0 0',
    },
    centerButton: {
      '&:hover': {
        backgroundColor: primaryColor,
        color: secondaryTextColor,
      },
      margin: '0 19px 0 0',
      [theme.breakpoints.down('lg')]: {
        margin: '0 0 0 0',
      },
    },
    rightButton: {
      '&:hover': {
        backgroundColor: whiteColor,
        color: primaryTextColor,
      },
    },
    alignRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tablet: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

interface Props {
  products: Product[];
  addMoreUnits?: (event: any) => void;

  startConfiguration?: (event: any) => void;

  onDeleteClick?: (event: any) => void;
}

export const ProductsOverview: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  // URL parameter for language and units setting.
  // Remember always to include them in the router.
  const urlParameters = useSelector(getLocalAndUnitsParameter);

  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={'38px'}>
        <Grid container item>
          {props.products.map((product, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ProductItem onDeleteClick={props.onDeleteClick} product={product}></ProductItem>
              </Grid>
            );
          })}
        </Grid>

        <Grid container item>
          <Grid item xs={6}>
            <PrimaryButton onClick={props.addMoreUnits} className={classes.leftButton}>
              <Typography variant="body1">{localized('ProductOverviewAddMore')}</Typography>
            </PrimaryButton>
          </Grid>

          <Grid item xs={6} className={classes.alignRight}>
            <Link to={routes.configuration + urlParameters} className={classes.link}>
              <PrimaryButton className={classes.rightButton} bgcolor={primaryColor} color={secondaryTextColor}>
                <Typography variant="body1">{localized('ProductOverviewContinueToConfigurator')}</Typography>
              </PrimaryButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});
