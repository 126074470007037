import {IProduct} from '../../api/api';
import {getUnitLenghtByCulture, getUnitRangebyCulture} from './culture-settings-helper';

export const getConfigurationParamByName = (product: IProduct | undefined, name: string) => {
  if (product && product.configurationParams) {
    return product.configurationParams.find((i) => i.name === name)?.value!;
  } else {
    return '';
  }
};
export const getConfigurationParamByNameAndCulture = (product: IProduct | undefined, name: string, unitsId: string) => {
  if (product && product.configurationParams) {
    let value = getUnitLenghtByCulture(unitsId, product.configurationParams.find((i) => i.name === name)?.value!);
    if (value) {
      return value;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
export const getConfigurationParamByNameAndCultureRange = (
  product: IProduct | undefined,
  name: string,
  unitsId: string,
) => {
  if (product && product.configurationParams) {
    return getUnitRangebyCulture(unitsId, product.configurationParams.find((i) => i.name === name)?.value!);
  } else {
    return '';
  }
};
