import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Product} from '../../../../api/api';
import {localized, localizedDynamic} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {disabledButtonColor} from '../../../../styles/color-constants';
import {getControlUnitInfoByEnum} from '../../../../utilities/helpers/configuration-contacts-helper';
import {getSmallImgByProductType} from '../../../../utilities/helpers/project-helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '41px 0 0 0',
    },
    showCursor: {
      cursor: 'pointer',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      margin: '20px 20px 20px 40px',
      height: 100,
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      margin: '41px 0 42px 36px',
    },
    row: {
      height: '150px',
      border: '1px solid',
      borderColor: disabledButtonColor,
      alignItems: 'center',
    },
    link: {
      display: 'flex',
      justifyContent: 'right',
      margin: '41px 40px 0 0',
      align: 'right',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    controlUnitFlexBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    marginRight10: {
      marginRight: 10,
    },
  }),
);

interface Props {
  product: Product;
  openPanel: boolean;
  summaryPage: boolean;
  callbackPanel: (status: boolean) => void;
}
export const ConfigurationItem: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const controlUnitInfo = getControlUnitInfoByEnum(props.product.controlUnit);
  const {project} = useSelector((store: AppState) => store.projectReducer);

  useEffect(() => {}, [project]);

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={1}>
              <Box className={classes.image}>
                <img src={getSmallImgByProductType(props.product.productType)}></img>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box className={classes.text}>
                <Typography variant="body2">{props.product.name && localizedDynamic(props.product.name)}</Typography>
                {props.summaryPage && (
                  <Box className={classes.controlUnitFlexBox}>
                    <Typography variant="body4" className={classes.marginRight10}>
                      {localized('ChosenUnitControl') + ': '}
                    </Typography>
                    <Typography variant="body4" className={classes.marginRight10}>
                      {localizedDynamic(controlUnitInfo.name)}
                    </Typography>
                    <Typography variant="body4" className={classes.marginRight10}>
                      {controlUnitInfo.id}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
