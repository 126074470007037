import {PaletteOptions} from '@mui/material/styles';
import { backgroundColor, primaryColor, primaryTextColor, secondaryColor, secondaryTextColor } from './color-constants';

export const DefaultPalette: PaletteOptions = {
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
  },
  background: {
    default: backgroundColor,
  },
  text: {
    primary: primaryTextColor,
    secondary: secondaryTextColor,
  },
  Example: {main: '#333'},
};
