export const routes = {
  template: '/template',
  home: '/home',
  productChooser: '/product-chooser',
  configuration: '/configuration',
  summary: '/summary',
  callback: '/callback',
  noPermission: '/no-permission',
};

// URL parameter for language and units setting.
// Gets by useParams(); and .[Id]
export const localsAndUnitsParameters = '/:localsId/:unitsId';
export const projectId = '/:projectId';

export const defaultRoute = routes.home + localsAndUnitsParameters;
