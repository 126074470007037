import {ControlUnit, ProductType} from '../../api/api';
import RoundContactAluSmall from '../../assets/products/control-units/alu88.png';
import RoundContactAluMedium from '../../assets/products/control-units/alu176.png';
import RoundContactBlackMedium from '../../assets/products/control-units/black176.png';
import RoundContactBlackSmall from '../../assets/products/control-units/black88.png';
import OvalContactSmall from '../../assets/products/control-units/oval.png';
import OvalRedContactSmall from '../../assets/products/control-units/OvalRodKontakt.png';
import OvalSilverContactSmall from '../../assets/products/control-units/OvalSolvKontakt.png';
import ManualContactSmall from '../../assets/products/control-units/remoteX1.png';
import ManualContactMedium from '../../assets/products/control-units/remoteX2.png';
export interface ControlUnitInfo {
  id: string;
  type: ControlUnit;
  name: string;
  imgSmall: string;
  imgLarge: string;
}

export const getControlUnitInfoByEnum = (controlUnit: ControlUnit) => {
  let controlUnitInfo: ControlUnitInfo = {
    id: '',
    type: ControlUnit.NotDefined,
    name: '',
    imgSmall: '',
    imgLarge: '',
  };

  switch (controlUnit) {
    case ControlUnit.NotDefined:
      controlUnitInfo = {
        id: '',
        type: ControlUnit.NotDefined,
        imgSmall: 'https://via.placeholder.com/57x57',
        imgLarge: 'https://via.placeholder.com/174x175',
        name: '',
      };
      break;
    case ControlUnit.RoundContactBlack:
      controlUnitInfo = {
        id: 'RK1354',
        type: ControlUnit.RoundContactBlack,
        imgSmall: RoundContactBlackSmall,
        imgLarge: RoundContactBlackMedium,
        name: 'RoundContactBlack',
      };
      break;
    case ControlUnit.RoundContactAlu:
      controlUnitInfo = {
        id: 'RK1355',
        type: ControlUnit.RoundContactAlu,
        imgSmall: RoundContactAluSmall,
        imgLarge: RoundContactAluMedium,
        name: 'RoundContactAlu',
      };
      break;
    case ControlUnit.OvalContactAlu:
      controlUnitInfo = {
        id: 'RK1054',
        type: ControlUnit.OvalContactAlu,
        imgSmall: OvalContactSmall,
        imgLarge: OvalContactSmall,
        name: 'OvalContactAlu',
      };
      break;
    case ControlUnit.OvalContactRed:
      controlUnitInfo = {
        id: 'RK1055',
        type: ControlUnit.OvalContactRed,
        imgSmall: OvalRedContactSmall,
        imgLarge: OvalRedContactSmall,
        name: 'OvalContactRed',
      };
      break;
    case ControlUnit.ManualOperation:
      controlUnitInfo = {
        id: 'RK1045',
        type: ControlUnit.ManualOperation,
        imgSmall: ManualContactSmall,
        imgLarge: ManualContactMedium,
        name: 'ManualOperation',
      };
      break;
    case ControlUnit.RoundContactBlackAndManual:
      controlUnitInfo = {
        id: 'RK1045',
        type: ControlUnit.RoundContactBlackAndManual,
        imgSmall: 'https://via.placeholder.com/57x57',
        imgLarge: 'https://via.placeholder.com/174x175',
        name: 'RoundContactBlackAndManual',
      };
      break;
    case ControlUnit.OvalContactAluAndManual:
      controlUnitInfo = {
        id: 'RK1045',
        type: ControlUnit.OvalContactAluAndManual,
        imgSmall: 'https://via.placeholder.com/57x57',
        imgLarge: 'https://via.placeholder.com/174x175',
        name: 'RoundContactAluAndManual',
      };
      break;

    default:
      controlUnitInfo = {
        id: '',
        type: ControlUnit.NotDefined,
        imgSmall: 'https://via.placeholder.com/57x57',
        imgLarge: 'https://via.placeholder.com/174x175',
        name: '',
      };
      break;
  }

  return controlUnitInfo;
};

export const getProductControlUnits = (productType: ProductType) => {
  let controlUnit = [] as ControlUnitInfo[];
  switch (productType) {
    case ProductType.Table1:
      controlUnit = tabel1ControlUnit;
      break;
    case ProductType.Table2:
      controlUnit = tabel2ControlUnit;
      break;
    case ProductType.Table3:
      controlUnit = tabel3ControlUnit;
      break;
    case ProductType.Table4:
      controlUnit = tabel4ControlUnit;
      break;
    case ProductType.WallCabinet1:
      controlUnit = wallCabinet1ControlUnit;
      break;
    case ProductType.WallCabinet2:
      controlUnit = wallCabinet2ControlUnit;
      break;

    default:
      break;
  }

  return controlUnit;
};

export const allTableContacts: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactBlackSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
  {
    id: 'RK1054',
    type: ControlUnit.OvalContactAlu,
    imgSmall: OvalSilverContactSmall,
    imgLarge: OvalSilverContactSmall,
    name: 'OvalContactAlu',
  },
  {
    id: 'RK1045',
    type: ControlUnit.ManualOperation,
    imgSmall: ManualContactSmall,
    imgLarge: ManualContactMedium,
    name: 'ManualOperation',
  },
];
export const allWallCabinetContacts: ControlUnitInfo[] = [
  {
    id: 'RK1355',
    type: ControlUnit.RoundContactAlu,
    imgSmall: RoundContactAluSmall,
    imgLarge: RoundContactAluMedium,
    name: 'RoundContactAlu',
  },
  {
    id: 'RK1055',
    type: ControlUnit.OvalContactRed,
    imgSmall: OvalRedContactSmall,
    imgLarge: OvalRedContactSmall,
    name: 'OvalContactRed',
  },
  {
    id: 'RK1045',
    type: ControlUnit.ManualOperation,
    imgSmall: ManualContactSmall,
    imgLarge: ManualContactMedium,
    name: 'ManualOperation',
  },
];

export const tabel1ControlUnit: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactBlackSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
  {
    id: 'RK1054',
    type: ControlUnit.OvalContactAlu,
    imgSmall: OvalContactSmall,
    imgLarge: OvalContactSmall,
    name: 'OvalContactAlu',
  },
  {
    id: 'RK1045',
    type: ControlUnit.ManualOperation,
    imgSmall: ManualContactSmall,
    imgLarge: ManualContactMedium,
    name: 'ManualOperation',
  },
];
export const tabel2ControlUnit: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactBlackSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
  {
    id: 'RK1054',
    type: ControlUnit.OvalContactAlu,
    imgSmall: OvalContactSmall,
    imgLarge: OvalContactSmall,
    name: 'OvalContactAlu',
  },
];
export const tabel3ControlUnit: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactBlackSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
  {
    id: 'RK1054',
    type: ControlUnit.OvalContactAlu,
    imgSmall: OvalContactSmall,
    imgLarge: OvalContactSmall,
    name: 'OvalContactAlu',
  },
];
export const tabel4ControlUnit: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactBlackSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
  {
    id: 'RK1054',
    type: ControlUnit.OvalContactAlu,
    imgSmall: OvalContactSmall,
    imgLarge: OvalContactSmall,
    name: 'OvalContactAlu',
  },
];
export const wallCabinet1ControlUnit: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactAluSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
  {
    id: 'RK1054',
    type: ControlUnit.OvalContactAlu,
    imgSmall: OvalContactSmall,
    imgLarge: OvalContactSmall,
    name: 'OvalContactAlu',
  },
  {
    id: 'RK1045',
    type: ControlUnit.ManualOperation,
    imgSmall: ManualContactSmall,
    imgLarge: ManualContactMedium,
    name: 'ManualOperation',
  },
];
export const wallCabinet2ControlUnit: ControlUnitInfo[] = [
  {
    id: 'RK1354',
    type: ControlUnit.RoundContactBlack,
    imgSmall: RoundContactBlackSmall,
    imgLarge: RoundContactBlackMedium,
    name: 'RoundContactBlack',
  },
];
