import {Box, Divider, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Product} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {getProjectByUrlId} from '../../../state/features/project/operation';
import {
  addProductToProject,
  allProducts,
  removeProductFromProject,
} from '../../../state/features/project/project-slice';
import {cultureUrlParameters as CultureUrlParameters} from '../../../state/features/urlSettings/types';
import {
  getCombinedUrlParameters,
  getLocalAndUnitsParameter,
  setCultureUrlSettings,
  setProjectUrlId,
} from '../../../state/features/urlSettings/url-settings-slice';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import { isCountryIdValid } from '../../../utilities/helpers/culture-settings-helper';
import {productStepsToDefaultProduct} from '../../../utilities/helpers/project-helper';
import {BasicBreadcrumbs} from '../../components/custom-components/basic-breadcrumbs';
import {ProductPicker} from '../../components/custom-components/product-picker';
import {ProductsOverview} from '../../components/custom-components/products-overview';
import {availableProducts, ProductSteps} from './products-list';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftalign: {
      alignItems: 'left',
      margin: '0 auto 0 auto',
      [theme.breakpoints.up('sm')]: {
        width: 800,
      },
      [theme.breakpoints.up('md')]: {
        width: 1100,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1400,
      },
    },
    showCursor: {
      cursor: 'pointer',
    },
    breadcrumbs: {
      margin: '17px 0 0 0',
    },
    title: {
      margin: '20px 0 20px 0',
    },
    intro: {
      height: '55px',
    },
    subtitle: {
      margin: '0 0 20px 0',
    },
    addunit: {
      margin: '20px 0 20px 0',
    },
  }),
);

const breadcrumbs = (urlParameters: string) => {
  return new Array(
    {
      routeName: routes.home + urlParameters,
      label: localized('IndivoProductConfigurator'),
    },
    {
      routeName: routes.productChooser + urlParameters,
      label: localized('ChooseLiftingUnit'),
    },
  );
};

export const Products: FC = React.memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector(allProducts);
  const [productClicked, setProductClicked] = useState<boolean>(true);

  // URL parameter for language and units setting.
  // Gets by useParams(); and .[Id]
  // Saves in store.
  // Remember always to include them in the router.
  const params = useParams();
  const localAndUnitsParameters = useSelector(getLocalAndUnitsParameter);
  const combinedUrlParameters = useSelector(getCombinedUrlParameters);
  const urlParameters = useSelector(getCombinedUrlParameters);
  const project = useSelector((store: AppState) => store.projectReducer.project);

  useEffect(() => {
    if (params.localsId && params.unitsId) {
      let cultureUrlParameters: CultureUrlParameters = {
        translationId: params.localsId.substring(0, params.localsId.indexOf('-')),
        contryId: isCountryIdValid(params.localsId.substring(params.localsId.indexOf('-') + 1)),
        unitsId: params.unitsId,
      };
      dispatch(setCultureUrlSettings(cultureUrlParameters));

      if (params.projectId) {
        dispatch(setProjectUrlId(params.projectId));
        if (!project) {
          dispatch(getProjectByUrlId(params.projectId));
        }
      } else if (project && project.urlParam) {
        dispatch(setProjectUrlId(project.urlParam));
        navigate(routes.productChooser + localAndUnitsParameters + '/' + project.urlParam);
      }
    }

    if (!project) navigate(routes.home + urlParameters);
  }, [
    params.translationId,
    params.unitsId,
    dispatch,
    products,
    navigate,
    urlParameters,
    params.projectId,
    project,
    localAndUnitsParameters,
    params.localsId,
  ]);

  const addNewProduct = useCallback(
    (product: ProductSteps) => {
      dispatch(addProductToProject(productStepsToDefaultProduct(product)));
      setProductClicked(true);
    },
    [dispatch],
  );

  const setProductNotClicked = useCallback(() => {
    setProductClicked(false);
  }, []);

  const removeSelectedProduct = useCallback(
    (product: Product) => {
      dispatch(removeProductFromProject(product));
    },
    [dispatch],
  );
  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container className={classes.leftalign}>
        <Grid item xs={12} className={classes.breadcrumbs}>
          <BasicBreadcrumbs breadcrumbs={breadcrumbs(combinedUrlParameters)} />
        </Grid>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h2">{localized('ProductPickerTitle')}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.intro}>
          <Typography variant="body1">{localized('ProductPickerIntro')}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.subtitle}>
          <Typography variant="h4">{localized('ProductPickerSubtitle')}</Typography>
        </Grid>

        {productClicked && products && products.length > 0 ? (
          <ProductsOverview
            addMoreUnits={setProductNotClicked}
            onDeleteClick={removeSelectedProduct}
            products={products}></ProductsOverview>
        ) : (
          <div>
            <Divider />
            <Grid item xs={12} className={classes.addunit}>
              <Typography variant="body3">{localized('ProductPickerAddUnit')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ProductPicker
                products={availableProducts ? availableProducts : []}
                onClick={addNewProduct}></ProductPicker>
            </Grid>
          </div>
        )}
      </Grid>
    </Box>
  );
});
