import {Box, Button, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, memo, useCallback, useState} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {disabledButtonColor, primaryColor, primaryTextColor, requiredErrorColor} from '../../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      margin: '0 8px 0 -8px',
    },
    button: {
      '&:hover': {
        backgroundColor: disabledButtonColor,
      },
      width: '90px',
      height: '50px',
      margin: '0 20px 0 0',
      textAlign: 'center',
      color: primaryTextColor,
      textTransform: 'none',
      font: 'normal normal normal 16px/21px Segoe UI',
      border: '3px solid',
      borderColor: disabledButtonColor,
      borderRadius: '0px',
    },
    buttonClicked: {
      backgroundColor: disabledButtonColor,
      border: '3px solid',
      borderColor: primaryColor,
    },
    error: {
      marginLeft: '12px',
      color: requiredErrorColor,
    },
  }),
);

interface Props {
  name: string;
  disabledBtn1?: boolean;
  disabledBtn2?: boolean;
  value1: string | boolean;
  value2: string | boolean;
  defaultValue?: string;
  btnLabel1: string;
  btnLabel2: string;
  formFieldName: string;
  callBack?: (event: boolean) => void;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const ButtonInput: FC<Props> = memo((props) => {
  const {
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useFormContext();

  const classes = useStyles();
  const [btn1Disabled, setBtn1Disabled] = useState<boolean>(
    getValues(props.formFieldName) === props.value1 || props.defaultValue === props.value1.toString(),
  );
  const [btn2Disabled, setBtn2Disabled] = useState<boolean>(
    getValues(props.formFieldName) === props.value2 || props.defaultValue === props.value2.toString(),
  );

  const handleBtn1Click = () => {
    setBtn1Disabled(true);
    setBtn2Disabled(false);
    setValue(props.formFieldName ?? '', props.value1 ?? '');
    if (props.callBack) {
      props.callBack(false);
    }
  };

  const handleBtn2Click = () => {
    setBtn1Disabled(false);
    setBtn2Disabled(true);
    setValue(props.formFieldName ?? '', props.value2 ?? '');
    if (props.callBack) {
      props.callBack(true);
    }
  };

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      defaultValue={props.defaultValue}
      render={({field}) => (
        <>
          <Box className={classes.box}>
            <Button
              className={clsx(classes.button, {[classes.buttonClicked]: btn1Disabled})}
              style={{backgroundColor: btn1Disabled ? disabledButtonColor : ''}}
              onClick={handleBtn1Click}>
              {props.btnLabel1}
            </Button>
            {errors[props.name] && errors[props.name].message ? (
              <Typography className={classes.error} variant="caption" display="block" gutterBottom>
                {errors[props.name].message}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
          <Box className={classes.box}>
            <Button
              className={clsx(classes.button, {[classes.buttonClicked]: btn2Disabled})}
              style={{backgroundColor: btn2Disabled ? disabledButtonColor : ''}}
              onClick={handleBtn2Click}>
              {props.btnLabel2}
            </Button>
          </Box>
        </>
      )}
    />
  );
});
