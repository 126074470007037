import {UserClient, OrganizationClient, ProjectClient, PdfClient} from '../api/api';
import {ApiBaseUrl} from '../utilities/constants';

export const userClient = new UserClient(ApiBaseUrl);

export const organizationClient = new OrganizationClient(ApiBaseUrl);
export const projectClient = new ProjectClient(ApiBaseUrl);

export const pdfClient = new PdfClient(ApiBaseUrl);
export type ApiClient = 
| UserClient
| OrganizationClient
| ProjectClient
| PdfClient;
