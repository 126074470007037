import {TextField, TextFieldProps, Theme} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC, memo} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: 'none',
      width: '200px',
      height: '50px',
    },
  }),
);

interface Props {
  name: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  focus?: boolean;
  callbackOnBlur?: () => void;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
  textFieldProps?: TextFieldProps;
  style?: any;
}

export const TextInput: FC<Props> = memo((props) => {
  const classes = useStyles();
  const {
    control,
    formState: {errors},
  } = useFormContext();



  return (
    <>
      <Controller
        name={props.name}
        control={control}
        rules={props.validators}
        defaultValue={props.defaultValue}
        render={({field}) => (
          <TextField
            {...field}
            className={classes.input}
            {...props.textFieldProps}
            disabled={props.disabled}
            sx={{marginTop: 1, marginBottom: 2}}
            InputLabelProps={{shrink: true}}
            value={field.value ?? ''}
            label={props.label}
            onBlur={props.callbackOnBlur}
            error={!!errors[props.name]}
            helperText={!!errors[props.name] && errors[props.name].message}
          />
        )}
      />
    </>
  );
});
