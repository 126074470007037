import DownloadIcon from '@mui/icons-material/Download';
import {Box, CircularProgress, Grid, SxProps, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {IProject, Product, ProductType, Project} from '../../../../api/api';
import {localized} from '../../../../i18n/i18n';
import {getPdfById} from '../../../../state/features/pdf/operation';
import {selectPdf} from '../../../../state/features/pdf/pdf-slice';
import {saveProject, updateProject} from '../../../../state/features/project/operation';
import {
  addCountryCalculationIdToProject,
  isAllProductsConfigured,
  setSelectedProduct,
} from '../../../../state/features/project/project-slice';
import {cultureUrlParameters} from '../../../../state/features/urlSettings/types';
import {getLocalAndUnitsParameter} from '../../../../state/features/urlSettings/url-settings-slice';
import {routes} from '../../../../state/routes';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  whiteColor,
} from '../../../../styles/color-constants';
import {SideMenuWizard} from '../../default-components/side-menu-wizard/side-menu-wizard';
import {PrimaryButton} from '../primary-button';
import {ConfigurationButtonSelector} from './configuration-button-selector';
import {ConfigurationItem} from './configuration-item';
import {ConfigurationItemComment} from './configuration-item-comment';
import {ConfigurationItemStart} from './configuration-item-start';
import {ConfigurationItemStatus} from './configuration-item-status';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftButton: {
      '&:hover': {
        backgroundColor: primaryColor,
        color: secondaryTextColor,
      },
      margin: '0 20px 0 0',
    },
    rightButton: {
      '&:hover': {
        backgroundColor: whiteColor,
        color: primaryTextColor,
      },
    },
    alignRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    rightButtonDisabled: {
      backgroundColor: disabledButtonColor,
      borderColor: disabledButtonColor,
      cursor: 'default',
    },
    link: {
      textDecoration: 'none',
    },
    linkDisable: {
      pointerEvents: 'none',
    },
    downloadPdfButtonBox: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
      marginTop: 50,
    },
    downloadButtonAlign: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center',
    },
    downloadIcon: {
      marginRight: 10,
    },
  }),
);

const buttonStyles: SxProps = {};

interface Props {
  products: Product[];
  summaryPage: boolean;
  startConfiguration?: (event: any) => void;
}

export const ConfigurationOverview: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {allProductsConfigured, project} = useSelector((store: AppState) => store.projectReducer);
  const cultureUrlParam = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const urlParameters = useSelector(getLocalAndUnitsParameter);
  const pdf = useSelector(selectPdf);
  const {pending} = useSelector((store: AppState) => store.pdfReducer);
  const [pdfDownloaded, setPdfDownloaded] = useState<boolean>(false);

  const startConfiguration = useCallback(
    (product: Product) => {
      dispatch(setSelectedProduct(product));
      setOpenPanel(true);
    },
    [dispatch],
  );

  const editConfiguration = useCallback(
    (product: Product) => {
      dispatch(setSelectedProduct(product));
      setOpenPanel(true);
    },
    [dispatch],
  );

  const setCultureSettingsOnProject = (project: IProject, settings: cultureUrlParameters) => {
    let newProject = new Project();
    newProject.urlParam = project.urlParam;
    newProject.name = project.name;
    newProject.city = project.city;
    newProject.customer = project.customer;
    newProject.contactPerson = project.contactPerson;
    newProject.products = project.products;
    newProject.country = project.country;
    // // If country form are US or AU the calculationId will change.
    // switch (project.country && getCountryCodeByName(project.country)) {
    //   case validCountryIds.us:
    //     newProject.countryCalculationId = validCountryIds.us;
    //     break;
    //   case validCountryIds.au:
    //     newProject.countryCalculationId = validCountryIds.au;
    //     break;
    //   default:
    //     newProject.countryCalculationId = settings.contryId;
    //     break;
    // }
    newProject.countryCalculationId = settings.contryId;
    newProject.unit = settings.unitsId;
    newProject.language = settings.translationId;
    return newProject;
  };

  const saveAndContinue = useCallback(() => {
    if (project && saveButtonEnabled) {
      dispatch(addCountryCalculationIdToProject(cultureUrlParam.contryId));

      if (project.urlParam) {
        let tempProject = {...project} as Project;
        tempProject.countryCalculationId = cultureUrlParam.contryId;
        tempProject.language = cultureUrlParam.translationId;
        dispatch(updateProject(tempProject));
      } else {
        const projectWithSettings = setCultureSettingsOnProject(project, cultureUrlParam);
        dispatch(saveProject(projectWithSettings as Project));
      }
    }
  }, [cultureUrlParam, dispatch, project, saveButtonEnabled]);

  const downloadPdf = async () => {
    if (project?.urlParam && !pending) {
      await dispatch(getPdfById(project.urlParam));
      setPdfDownloaded(true);
    }
  };
  useEffect(() => {
    if (pdfDownloaded) {
      const blob = new Blob([pdf.data], {type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Configuration-${dayjs(new Date()).format('MM-DD-YYYY')}.pdf`;
      link.click();
      setPdfDownloaded(false);
    }
  }, [pdf, pdfDownloaded]);

  // Check if products include other producttypes than Table2 and Table4, which should not have operations displayed
  const includesOtherProducts = props.products.some(
    (p) =>
      p.productType == ProductType.Table1 ||
      p.productType == ProductType.Table3 ||
      p.productType == ProductType.WallCabinet1,
  );
  useEffect(() => {
    if (project && project.products) {
      setSaveButtonEnabled(
        allProductsConfigured &&
          project.products.every((i) => {
            return (
              i.controlUnit !== undefined || i.productType == ProductType.Table2 || i.productType == ProductType.Table4
            );
          }),
      );
    }
    dispatch(isAllProductsConfigured());
  }, [allProductsConfigured, dispatch, project, saveButtonEnabled]);

  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={'38px'}>
        {!props.summaryPage && allProductsConfigured && includesOtherProducts && (
          <Grid container item>
            <ConfigurationButtonSelector products={props.products} />
          </Grid>
        )}

        <Grid container item>
          {project &&
            project.products &&
            project.products.map((product, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ConfigurationItem
                    product={product}
                    openPanel={openPanel}
                    summaryPage={props.summaryPage}
                    callbackPanel={setOpenPanel}>
                    {props.summaryPage ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <ConfigurationItemComment product={product} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={6}>
                          <ConfigurationItemStart
                            product={product}
                            isConfigured={product.configured}
                            startConfiguration={startConfiguration}
                            editConfiguration={editConfiguration}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <ConfigurationItemStatus isConfigured={product.configured} />
                        </Grid>
                      </Grid>
                    )}
                  </ConfigurationItem>
                  <SideMenuWizard openPanel={openPanel} onClosePanelPressed={setOpenPanel} />
                </Grid>
              );
            })}
        </Grid>

        {!props.summaryPage && (
          <>
            {/* {allProductsConfigured && (
              <Grid container item>
                <ConfigurationButtonSelector products={props.products} />
              </Grid>
            )} */}

            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Link to={routes.productChooser + urlParameters} className={classes.link}>
                  <PrimaryButton className={classes.leftButton}>
                    <Typography variant="body1">{localized('AddOrRemoveLiftingUnit')}</Typography>
                  </PrimaryButton>
                </Link>
              </Grid>

              <Grid item xs={6} className={classes.alignRight}>
                <Link
                  className={clsx(classes.link, {[classes.linkDisable]: !saveButtonEnabled})}
                  to={routes.summary + urlParameters}>
                  <PrimaryButton
                    onClick={saveAndContinue}
                    className={clsx({
                      [classes.rightButton]: saveButtonEnabled,
                      [classes.rightButtonDisabled]: !saveButtonEnabled,
                    })}
                    bgcolor={primaryColor}
                    color={secondaryTextColor}>
                    <Typography variant="body1">
                      {saveButtonEnabled ? localized('SaveAndContinue') : localized('ShowOperationOptions')}
                    </Typography>
                  </PrimaryButton>
                </Link>
              </Grid>
            </Grid>
          </>
        )}
        {props.summaryPage && (
          <Box className={classes.downloadPdfButtonBox}>
            <PrimaryButton
              onClick={downloadPdf}
              bgcolor={primaryColor}
              color={secondaryTextColor}
              className={clsx(classes.downloadButtonAlign, {
                [classes.rightButtonDisabled]: !project?.urlParam,
              })}>
              {!pending ? (
                <>
                  <DownloadIcon className={classes.downloadIcon}></DownloadIcon>
                  <Typography variant="body1">{localized('DownloadPdf')}</Typography>
                </>
              ) : (
                <CircularProgress color="secondary"></CircularProgress>
              )}
            </PrimaryButton>
          </Box>
        )}
      </Grid>
    </Box>
  );
});
