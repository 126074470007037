import {Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {localized} from '../../../i18n/i18n';
import {getProjectByUrlId} from '../../../state/features/project/operation';
import {allProducts} from '../../../state/features/project/project-slice';
import {cultureUrlParameters} from '../../../state/features/urlSettings/types';
import {
  getCombinedUrlParameters,
  getLocalAndUnitsParameter,
  setCultureUrlSettings,
  setProjectUrlId,
} from '../../../state/features/urlSettings/url-settings-slice';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {isCountryIdValid} from '../../../utilities/helpers/culture-settings-helper';
import {BasicBreadcrumbs, Breadcrumb} from '../../components/custom-components/basic-breadcrumbs';
import {ConfigurationOverview} from '../../components/custom-components/configuration/configuration-overview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      alignItems: 'left',
      margin: '0 auto 0 auto',
      [theme.breakpoints.up('sm')]: {
        width: 800,
      },
      [theme.breakpoints.up('md')]: {
        width: 1100,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1400,
      },
    },
    showCursor: {
      cursor: 'pointer',
    },
    breadcrumbs: {
      margin: '17px 0 0 0',
    },
    title: {
      margin: '20px 0 20px 0',
    },
    intro: {
      height: '55px',
    },
    subtitle: {
      margin: '30px 0 20px 0',
    },
    products: {
      margin: '0 0 20px 0',
    },
  }),
);

const breadcrumbs = (urlParameters: string) => {
  return new Array(
    {
      routeName: routes.home + urlParameters,
      label: localized('IndivoProductConfigurator'),
    },
    {
      routeName: routes.productChooser + urlParameters,
      label: localized('ChooseLiftingUnit'),
    },
    {
      routeName: routes.configuration + urlParameters,
      label: localized('Configuration'),
    },
  ) as Breadcrumb[];
};

export const Configuration: FC = React.memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const products = useSelector(allProducts);

  // URL parameter for language and units setting.
  // Gets by useParams(); and .[Id]
  // Saves in store.
  // Remember always to include them in the router.
  const params = useParams();
  const localAndUnitsParameters = useSelector(getLocalAndUnitsParameter);
  const urlParameters = useSelector(getCombinedUrlParameters);
  const project = useSelector((store: AppState) => store.projectReducer.project);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.localsId && params.unitsId) {
      let cultureUrlParameters: cultureUrlParameters = {
        translationId: params.localsId.substring(0, params.localsId.indexOf('-')),
        contryId: isCountryIdValid(params.localsId.substring(params.localsId.indexOf('-') + 1)),
        unitsId: params.unitsId,
      };
      dispatch(setCultureUrlSettings(cultureUrlParameters));

      if (params.projectId) {
        dispatch(setProjectUrlId(params.projectId));
        if (!project) {
          dispatch(getProjectByUrlId(params.projectId));
        }
      } else if (project && project.urlParam) {
        dispatch(setProjectUrlId(project.urlParam));
        navigate(routes.configuration + localAndUnitsParameters + '/' + project.urlParam);
      }
    } else if (!products) navigate(routes.home + urlParameters);
  }, [
    params.translationId,
    params.unitsId,
    dispatch,
    products,
    navigate,
    urlParameters,
    params.projectId,
    project,
    localAndUnitsParameters,
    params.localsId,
  ]);

  return (
    <Grid container className={classes.left}>
      <Grid item xs={12} className={classes.breadcrumbs}>
        <BasicBreadcrumbs breadcrumbs={breadcrumbs(urlParameters)} />
      </Grid>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h2">{localized('ConfigureYourLiftingUnits')}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.intro}>
        <Typography width="593px" variant="body1">
          {localized('ConfigurationIntro')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.subtitle}>
        <Typography variant="h4">{localized('ProductPickerSubtitle')}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.products}>
        {products && <ConfigurationOverview products={products} summaryPage={false} />}
      </Grid>
    </Grid>
  );
});
