import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {RedirectUriStorageKey} from '../utilities/constants';
import {useAuth} from './auth-provider';

export const Callback: FC = () => {
  const {isAuthenticated, logout} = useAuth();
  let redirectUrl: string | null = window.sessionStorage.getItem(RedirectUriStorageKey);
  const navigate = useNavigate();

  if (isAuthenticated && redirectUrl) {
    window.sessionStorage.removeItem(RedirectUriStorageKey);
    navigate(redirectUrl);
    return null;
  } else {
    logout();
    return null;
  }
};
