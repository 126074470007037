import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC} from 'react';
import {localizedDynamic} from '../../../i18n/i18n';
import {ProductSteps} from '../../pages/products/products-list';
import {BasicCard} from '../default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    text: {
      margin: '32px 0 33px 0',
      alignItems: 'left',
    },
    card: {
      width: '267px',
      height: '322px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #DCDCDC',
      boxShadow: 'none',
      opacity: 1,
      margin: '0px 0px 0px 0px',
      padding: '0px',
      '&:hover': {
        boxShadow: '2px 2px 10px lightgrey',
        cursor: 'pointer',
      },
    },
    img: {
      display: 'flex',
      justifyContent: 'center',
      margin: '30px 40px 30px 40px',
      height: 175,
    },
    box: {
      margin: '32px 33px 29px 27px',
    },
    grid: {
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }),
);

interface Props {
  products: ProductSteps[];
  onClick?: (event: any) => void;
}

export const ProductPicker: FC<Props> = React.memo((props) => {
  const classes = useStyles();

  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container direction="row" className={classes.grid}>
        {props.products.map((product) => (
          <Grid item key={product.type}>
            <BasicCard className={classes.card} onClick={props.onClick?.bind(this, product)}>
              <Box className={classes.img}>
                <img src={product.largeImg}></img>
              </Box>
              <Box className={classes.box}>
                <Typography variant="body2" className={classes.text}>
                  {localizedDynamic(product.description)}
                </Typography>
              </Box>
            </BasicCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});
