import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import product4IllustrationImg from '../../../../assets/products/product-4/product4Illustration.png';
import {localized, localizedDynamic, localizedInterpolation} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';
import {
  getUnitLenghtByCulture,
  getUnitLengthNameByCulture,
  getValueLengthWithUnitByCulture,
} from '../../../../utilities/helpers/culture-settings-helper';
import {getConfigurationParamByNameAndCulture} from '../../../../utilities/helpers/product-helper';
import {requiredValidatorDynamicMsg} from '../../../../utilities/platform-helpers/validator-helper';
import {TextInputLabel} from '../../../components/custom-components/form/text-input-label';
import {TextInput} from '../../../components/default-components/form/text-input';
import {worktopDepth, worktopLength} from '../configuration-constants';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '686px',
      [theme.breakpoints.down('lg')]: {
        width: '500px',
      },
    },
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    formLabel: {
      display: 'flex',
      justifyContent: 'center',
    },
    formTextInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        border: '1px solid',
        borderColor: disabledButtonColor,
        borderRadius: 0,
      },
    },
    formBtnInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    unitText: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginLeft: 10,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: primaryColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: primaryColor,
      color: primaryTextColor,
    },
    input: {
      border: 'none',
      width: '200px',
      height: '50px',
    },
    gridItem: {
      height: '140px',
    },
  }),
);

export interface PropsFromParent {}

export const Product4Step1: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const [showPlacementWarning, setShowPlacmentWarning] = useState<boolean>(false);
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const {unitsId} = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);

  const useCaseBtnCallback = useCallback((value: boolean) => {
    setShowPlacmentWarning(!value);
  }, []);

  useEffect(() => {}, [unitsId]);

  const ImageRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" height={420}>
            <img src={product4IllustrationImg}></img>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableLengthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={worktopLength}
            label={localized('LengthOfTheTableTop') + ' (A)'}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '1400'),
                value2: getValueLengthWithUnitByCulture(unitsId, '3000'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct4Step1_1', {
              value1: getValueLengthWithUnitByCulture(unitsId, '1400'),
              value2: getValueLengthWithUnitByCulture(unitsId, '3000'),
            })}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            <TextInput
              name={worktopLength}
              defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, worktopLength, unitsId)}
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                min: {
                  value: Number(getUnitLenghtByCulture(unitsId, '1400')),
                  message: 'Min. ' + getValueLengthWithUnitByCulture(unitsId, '1400'),
                },
                max: {
                  value: Number(getUnitLenghtByCulture(unitsId, '3000')),
                  message: 'Max. ' + getValueLengthWithUnitByCulture(unitsId, '3000'),
                },
                pattern: {
                  value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                  message: localized('PleaseEnterANumber'),
                },
              }}
            />

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableDepthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={7}>
          <TextInputLabel
            name={worktopDepth}
            label={localized('DepthOfTheTableTop') + ' (B)'}
            hintLabel={
              '(' +
              localizedInterpolation('BetweenXandY', {
                value1: getValueLengthWithUnitByCulture(unitsId, '700'),
                value2: getValueLengthWithUnitByCulture(unitsId, '1000'),
              }) +
              ')'
            }
            tooltipLabel={localizedInterpolation('ToolTipProduct4Step1_2', {
              value1: getValueLengthWithUnitByCulture(unitsId, '700'),
              value2: getValueLengthWithUnitByCulture(unitsId, '1000'),
            })}
          />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.formTextInputBox}>
            {
              <TextInput
                name={worktopDepth}
                defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, worktopDepth, unitsId)}
                validators={{
                  ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                  min: {
                    value: getUnitLenghtByCulture(unitsId, '700'),
                    message:
                      localizedDynamic('ValueMustBeOver') + ' ' + getValueLengthWithUnitByCulture(unitsId, '700'),
                  },
                  max: {
                    value: getUnitLenghtByCulture(unitsId, '1000'),
                    message:
                      localizedDynamic('ValueMustBeUnder') + ' ' + getValueLengthWithUnitByCulture(unitsId, '1000'),
                  },
                  pattern: {
                    value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
                    message: localized('PleaseEnterANumber'),
                  },
                }}
              />
            }

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const WarningRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body3" color="error">
            {localizedInterpolation('PlacementWarningX', {value1: getValueLengthWithUnitByCulture(unitsId, '70')})}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid container spacing={6}>
            <ImageRow />
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableLengthRow />
          </Grid>

          <Grid container spacing={6} className={classes.gridItem}>
            <TableDepthRow />
          </Grid>

          {showPlacementWarning && (
            <Grid container spacing={6} className={classes.gridItem}>
              <WarningRow />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
});
