import {Box, Button, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {IProject, Project} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {updateProjectInfo} from '../../../state/features/project/project-slice';
import {cultureUrlParameters} from '../../../state/features/urlSettings/types';
import {
  getCombinedUrlParameters,
  setCultureUrlSettings,
  setProjectUrlId,
} from '../../../state/features/urlSettings/url-settings-slice';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {disabledButtonColor, primaryTextColor} from '../../../styles/color-constants';
import {nameof} from '../../../utilities/platform-helpers/nameof-helper';
import {TextInputLabel} from '../../components/custom-components/form/text-input-label';
import {PrimaryButton} from '../../components/custom-components/primary-button';
import {FlexColumn} from '../../components/default-components/flex-column';
import {Form} from '../../components/default-components/form/form';
import {TextInput} from '../../components/default-components/form/text-input';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {requiredValidatorDynamicMsg} from '../../../utilities/platform-helpers/validator-helper';
import {isCountryIdValid} from '../../../utilities/helpers/culture-settings-helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      alignItems: 'center',
      marginTop: 50,
    },
    box: {
      textAlign: 'center',
      width: 600,
    },
    link: {
      textDecoration: 'none',
      color: primaryTextColor,
    },
    formTextInputBox: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        border: '1px solid',
        borderColor: disabledButtonColor,
        borderRadius: 0,
      },
    },
    formFlexBox: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: '20px 0px 30px 0px',
    },
    formItemFlexBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 90,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),
);

export interface PropsFromParent {}

export const Produktkonfigurator: FC = React.memo(({...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector((store: AppState) => store.projectReducer.project);

  // URL parameter for language and units setting.
  // Gets by useParams(); and .[Id]
  // Saves in store.
  // Remember always to include them in the router.
  const params = useParams();
  const urlParameters = useSelector(getCombinedUrlParameters);

  useEffect(() => {
    if (params.localsId && params.unitsId) {
      let cultureUrlParameters: cultureUrlParameters = {
        translationId: params.localsId.substring(0, params.localsId.indexOf('-')),
        contryId: isCountryIdValid(params.localsId.substring(params.localsId.indexOf('-') + 1)),
        unitsId: params.unitsId,
      };
      dispatch(setCultureUrlSettings(cultureUrlParameters));

      if (params.projectId) {
        dispatch(setProjectUrlId(params.projectId));
        if (!project) {
          navigate(
            routes.summary +
              '/' +
              cultureUrlParameters.translationId +
              '-' +
              cultureUrlParameters.contryId +
              '/' +
              cultureUrlParameters.unitsId +
              '/' +
              params.projectId,
          );
        }
      }
    }
  }, [
    params.translationId,
    params.unitsId,
    dispatch,
    navigate,
    urlParameters,
    params.projectId,
    project,
    params.localsId,
  ]);

  const startButtonPressed = useCallback(
    (data: Project) => {
      if (project && project.urlParam) {
        let temp: IProject = {
          ...project,
          name: data.name,
          city: data.city,
          customer: data.customer,
          contactPerson: data.contactPerson,
        };
        dispatch(updateProjectInfo(temp as Project));
        navigate(routes.configuration + urlParameters);
      } else {
        dispatch(updateProjectInfo(data));
        navigate(routes.productChooser + urlParameters);
      }
    },
    [dispatch, navigate, project, urlParameters],
  );

  return (
    <>
      <PageTitle title="Home">
        <PageContentContainer>
          <FlexColumn className={classes.center}>
            <Typography variant="h1">{'INDIVO'}</Typography>
            <Typography variant="h3">{localized('ProductConfigurator')}</Typography>
            <Box className={classes.box} m={3}>
              <Typography variant="body1">{localized('WelcomeText')}</Typography>
            </Box>
            <Form onSubmit={startButtonPressed} resetValues={true}>
              <Box className={classes.formFlexBox}>
                <Box className={classes.formItemFlexBox}>
                  <TextInputLabel
                    name={nameof<Project>('name')}
                    label={localized('ProjectName')}
                    tooltipLabel={localized('ProjectNameDescription')}
                    removeTopMargin={true}
                  />
                  <Box className={classes.formTextInputBox}>
                    <TextInput
                      name={nameof<Project>('name')}
                      defaultValue={project && project.name}
                      validators={{
                        ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.formItemFlexBox}>
                  <TextInputLabel
                    name={nameof<Project>('country')}
                    label={localized('Country')}
                    tooltipLabel={localized('CountryDescription')}
                    removeTopMargin={true}
                  />
                  <Box className={classes.formTextInputBox}>
                    <TextInput
                      name={nameof<Project>('country')}
                      defaultValue={project && project.country}
                      validators={{
                        ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.formItemFlexBox}>
                  <TextInputLabel
                    name={nameof<Project>('city')}
                    label={localized('City')}
                    tooltipLabel={localized('CityDescription')}
                    removeTopMargin={true}
                  />
                  <Box className={classes.formTextInputBox}>
                    <TextInput
                      name={nameof<Project>('city')}
                      defaultValue={project && project.city}
                      validators={{
                        ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.formItemFlexBox}>
                  <TextInputLabel
                    name={nameof<Project>('customer')}
                    label={localized('Customer')}
                    tooltipLabel={localized('CustomerDescription')}
                    removeTopMargin={true}
                  />
                  <Box className={classes.formTextInputBox}>
                    <TextInput
                      name={nameof<Project>('customer')}
                      defaultValue={project && project.customer}
                      validators={{
                        ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.formItemFlexBox}>
                  <TextInputLabel
                    name={nameof<Project>('contactPerson')}
                    label={localized('ContactPerson')}
                    tooltipLabel={localized('ContactPersonDescription')}
                    removeTopMargin={true}
                  />
                  <Box className={classes.formTextInputBox}>
                    <TextInput
                      name={nameof<Project>('contactPerson')}
                      defaultValue={project && project.contactPerson}
                      validators={{
                        ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.buttonBox}>
                <Button type="submit" sx={{textTransform: 'none'}}>
                  <PrimaryButton>
                    <Typography variant="body1">{localized('StartConfigurator')}</Typography>
                  </PrimaryButton>
                </Button>
              </Box>
            </Form>
          </FlexColumn>
        </PageContentContainer>
      </PageTitle>
    </>
  );
});
