import jwtDecode, {JwtPayload} from 'jwt-decode';
import {tokenStorageKey} from '../../auth/auth-constants';

type TokenProps = 'Email' | 'sub';

export function getAccessTokenFromLocalStorage() {
  return '';
}

function getFieldFromToken(field: TokenProps, token?: string | null) {
  if (!token) {
    token = localStorage.getItem(tokenStorageKey);
  }
  if (token) {
    const decodedToken = jwtDecode<any>(token);
    let tokenField = '';

    if (decodedToken) {
      tokenField = decodedToken[field] || '';
    }

    return tokenField;
  }
}

export function getEmailFromToken(token?: string | null) {
  return getFieldFromToken('Email', token);
}

export function getTokenExpiresAt(tokenId: string) {
  let token = localStorage.getItem(tokenId);
  if (token) {
    let jwtToken = jwtDecode<JwtPayload>(token);
    let expiresAt = jwtToken.exp!;
    let expiresAtDate = new Date(expiresAt * 1000);
    return expiresAtDate;
  }
  return null;
}

export function getUserRoles(tokenId: string = tokenStorageKey) {
  let token = localStorage.getItem(tokenId);

  if (token) {
    let jwtToken = jwtDecode<any>(token);

    if (jwtToken.roles && jwtToken.roles.length > 0) {
      return jwtToken.roles;
    }
  }
  return '';
}

export function getUseRoleByToken(token: string) {
  if (token) {
    let jwtToken = jwtDecode<any>(token);

    if (jwtToken.roles && jwtToken.roles.length > 0) {
      return jwtToken.roles[0];
    }
  }
  return '';
}

export function isTokenExpired(tokenId: string = tokenStorageKey) {
  let expireDate = getTokenExpiresAt(tokenId);
  let currentTime = new Date();
  if (expireDate) {
    return currentTime > expireDate;
  }
  return true;
}

export function getSubFromToken(token?: string | null): string | undefined {
  return getFieldFromToken('sub', token);
}
