import {Box, Grid, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {getConfigurationParamByName} from '../../../utilities/helpers/product-helper';
import {TextInput} from '../../components/default-components/form/text-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '686px',
      [theme.breakpoints.down('lg')]: {
        width: '500px',
      },
    },
    textField: {
      flexGrow: 1,
      marginTop: 20,
    },
  }),
);

export interface PropsFromParent {}

export const ProductCommentStep: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const myRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({block: 'end'});
    }
  }, []);

  const CommentRow = () => {
    return (
      <>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Typography variant="body1">{localized('HereYouCanAddACommentToConfiguration')}</Typography>
            <TextInput
              name="ProductComment"
              defaultValue={getConfigurationParamByName(selectedProduct, 'ProductComment')}
              textFieldProps={{
                multiline: true,
                rows: 15,
                variant: 'filled',
                className: classes.textField,
              }}
            />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid ref={myRef} container item spacing={6}>
            <CommentRow></CommentRow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
