import React, {FC} from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

interface Props {
  size?: string | number | undefined;
}

export const BasicSpinner: FC<Props> = React.memo((props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={props.size} />
    </div>
  );
});
