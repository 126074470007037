import SquareSharpIcon from '@mui/icons-material/SquareSharp';
import {Box, Grid, List, ListItem, ListItemIcon, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import product3IllustrationImg from '../../../../assets/products/product-3/product3Illustration.png';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {
  disabledButtonColor,
  greyFadedColor,
  primaryColor,
  primaryTextColor,
  sidebarSelectedBorder,
  whiteColor,
} from '../../../../styles/color-constants';
import {
  getUnitLengthNameByCulture,
  getValueLengthWithUnitByCulture,
  getValueRangeWithUnitByCulture,
} from '../../../../utilities/helpers/culture-settings-helper';
import {
  getConfigurationParamByName,
  getConfigurationParamByNameAndCulture,
} from '../../../../utilities/helpers/product-helper';
import {requiredValidatorDynamicMsg} from '../../../../utilities/platform-helpers/validator-helper';
import {ButtonInput} from '../../../components/custom-components/form/button-input';
import {TextInputLabel} from '../../../components/custom-components/form/text-input-label';
import {DropdownOption} from '../../../components/default-components/form/form-types';
import {SelectInput} from '../../../components/default-components/form/select-input';
import {TextInput} from '../../../components/default-components/form/text-input';
import {frameDepth, frameLength, hasFascia, numberOfTableFrameFittings} from '../configuration-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '686px',
    },
    hintText: {
      color: sidebarSelectedBorder,
      fontSize: 12,
    },
    infoIcon: {
      color: primaryColor,
      marginRight: 10,
    },
    formTextBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    formInputBox: {
      display: 'flex',
      justifyContent: 'center',
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    unitText: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginLeft: 10,
    },
    arrow: {
      '&:before': {
        border: '1px solid',
        borderColor: greyFadedColor,
      },
      color: whiteColor,
    },
    tooltip: {
      backgroundColor: whiteColor,
      border: '1px solid',
      borderColor: greyFadedColor,
      color: primaryTextColor,
    },
    list: {
      display: 'list-item',
      margin: '0 0 0 -17px',
    },
    listText: {
      margin: '0 0 0 -35px',
    },
    buttonClicked: {
      border: '3px solid',
      borderColor: primaryColor,
      backgroundColor: disabledButtonColor,
      width: '90px',
      height: '50px',
      margin: '0 20px 0 0',
      textAlign: 'center',
      color: primaryTextColor,
      textTransform: 'none',
      font: 'normal normal normal 16px/21px Segoe UI',
      borderRadius: '0px',
    },
    select: {
      margin: '0 20px 0 0',
    },
    button: {
      width: '90px',
      height: '50px',
      margin: '0 20px 0 0',
      textAlign: 'center',
      color: primaryTextColor,
      textTransform: 'none',
      font: 'normal normal normal 16px/21px Segoe UI',
      border: '3px solid',
      borderColor: disabledButtonColor,
      borderRadius: '0px',
    },
    recommendedText: {
      margin: '0 0 0 35px',
    },
    gridItem: {
      height: '120px',
    },
    gridITexttem: {
      height: '80px',
    },
  }),
);

export interface PropsFromParent {}

export const Product3Step2: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const selectedProduct = useSelector((store: AppState) => store.projectReducer.selectedProduct);
  const {unitsId} = useSelector((store: AppState) => store.urlSettingsReducer.cultureUrlParameters);
  const [clicked, setClicked] = useState<boolean>(false);
  const [tableFrameOptions, setTableFrameOptions] = useState<DropdownOption[]>([
    {label: localized('ZeroPackages'), value: 0},
    {label: localized('OnePackages'), value: 1},
    {label: localized('TwoPackages'), value: 2},
    {label: localized('ThreePackages'), value: 3},
    {label: localized('FourPackages'), value: 4},
    {label: localized('FivePackages'), value: 5},
  ]);
  const {getValues, setValue} = useFormContext();
  const myRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({block: 'end'});
    }
  }, []);
  const useCaseBtnCallback = useCallback(
    (value: boolean) => {
      setClicked(value);
      if (!value) {
        setValue(frameLength, null);
        setValue(frameDepth, null);
        setValue(numberOfTableFrameFittings, null);
      }
    },
    [setValue],
  );

  const packagesCallback = useCallback(() => {
    let length = Number(getValues(frameLength));
    let depth = Number(getValues(frameDepth));
    let packages = Math.ceil((length + depth) / (unitsId == 'iu' ? 39.37 : 1000)) * 2;
    let hasOption = tableFrameOptions?.some((option) => option['value'] === packages);
    if (!hasOption) {
      setTableFrameOptions([
        ...tableFrameOptions,
        {
          label: localizedInterpolation('NPackages', {
            value: packages,
          }),
          value: packages,
        },
      ]);
    }
    setValue(numberOfTableFrameFittings, packages);
  }, [getValues, setValue, tableFrameOptions, unitsId]);

  const IntroRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1">{localized('FormCasketIntro')}</Typography>
          <List className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <SquareSharpIcon sx={{fontSize: 10}} color="primary"></SquareSharpIcon>
              </ListItemIcon>
              <Typography className={classes.listText} variant="body1">
                {localized('FormCasketBulletPointOne')}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SquareSharpIcon sx={{fontSize: 10}} color="primary"></SquareSharpIcon>
              </ListItemIcon>
              <Typography className={classes.listText} variant="body1">
                {localized('FormCasketBulletPointTwo')}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SquareSharpIcon sx={{fontSize: 10}} color="primary"></SquareSharpIcon>
              </ListItemIcon>
              <Typography className={classes.listText} variant="body1">
                {localizedInterpolation('FormCasketBulletPointThree', {
                  value1: getValueLengthWithUnitByCulture(unitsId, '70'),
                })}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </React.Fragment>
    );
  };

  const HasTableFrameRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <Typography variant="body1">{localized('HasTableFrame')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <ButtonInput
              name={hasFascia}
              formFieldName={hasFascia}
              validators={{...requiredValidatorDynamicMsg(localized('FieldIsRequired'))}}
              callBack={useCaseBtnCallback}
              value1={'true'}
              value2={'false'}
              defaultValue={getConfigurationParamByName(selectedProduct, hasFascia)}
              btnLabel1={localized('Yes')}
              btnLabel2={localized('No')}></ButtonInput>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const ImageRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" height={420}>
            <img src={product3IllustrationImg}></img>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const CasketRecommendationRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1">
            {localizedInterpolation('CasketRecommendation', {
              value1: getValueLengthWithUnitByCulture(unitsId, '70'),
              value2: getValueRangeWithUnitByCulture(unitsId, '16', '30'),
            })}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const TableFrameLengthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextInputLabel
            name={frameLength}
            label={localized('TableFrameLength') + ' (C)'}
            hintLabel={'(' + localized('RecommendTabelTopFullLength') + ')'}
            tooltipLabel={localized('ToolTipProduct1Step2_2')}></TextInputLabel>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <TextInput
              name={frameLength}
              defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, frameLength, unitsId)}
              // validators={{
              //   ...requiredValidator,
              //   pattern: {
              //     value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
              //     message: localized('PleaseEnterANumber'),
              //   },
              // }}
              callbackOnBlur={packagesCallback}></TextInput>

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const TableFrameDepthRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextInputLabel
            name={frameDepth}
            label={localized('TableFrameDepth') + ' (D)'}
            tooltipLabel={localizedInterpolation('ToolTipProduct3Step2_3', {
              value1: getUnitLengthNameByCulture(unitsId),
            })}></TextInputLabel>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <TextInput
              name={frameDepth}
              defaultValue={getConfigurationParamByNameAndCulture(selectedProduct, frameDepth, unitsId)}
              // validators={{
              //   ...requiredValidator,
              //   pattern: {
              //     value: /^(-?(?:\d+(?:\/-?\d*)?)?).*$/,
              //     message: localized('PleaseEnterANumber'),
              //   },
              // }}
              callbackOnBlur={packagesCallback}></TextInput>

            <Typography className={classes.unitText}>{getUnitLengthNameByCulture(unitsId)}</Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const RecommondetionTextRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography className={classes.recommendedText} variant="body1">
            {localized('TableFrameMountingSuggestion')}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const NumberOfTalbeFramePackagesRow = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextInputLabel
            name="NumberOfTableFrameFittings"
            label={localized('NumberOfTableFrameFittings')}
            hintLabel={localized('ContainsFourFittings')}
            tooltipLabel={localized('ToolTipProduct1Step2_4')}></TextInputLabel>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formInputBox}>
            <SelectInput
              name={numberOfTableFrameFittings}
              defaultValue={Number(getConfigurationParamByName(selectedProduct, numberOfTableFrameFittings))}
              noDefault
              validators={{
                ...requiredValidatorDynamicMsg(localized('FieldIsRequired')),
              }}
              options={tableFrameOptions}></SelectInput>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const NoTabelFrameWarning = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body3" color="error">
            {localized('NoTableFrameWarning')}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{flexGrow: 1}}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid ref={myRef} container spacing={3}>
            <IntroRow></IntroRow>
          </Grid>

          <Grid container spacing={3}>
            <HasTableFrameRow></HasTableFrameRow>
          </Grid>

          {!clicked ? (
            <>
              <Grid container spacing={3}>
                <ImageRow></ImageRow>
              </Grid>

              <Grid container spacing={3} className={classes.gridItem}>
                <CasketRecommendationRow></CasketRecommendationRow>
              </Grid>

              <Grid container spacing={3} className={classes.gridItem}>
                <TableFrameLengthRow></TableFrameLengthRow>
              </Grid>

              <Grid container spacing={3} className={classes.gridItem}>
                <TableFrameDepthRow></TableFrameDepthRow>
              </Grid>

              <Grid container spacing={1} className={classes.gridITexttem}>
                <RecommondetionTextRow></RecommondetionTextRow>
              </Grid>

              <Grid container spacing={3} className={classes.gridItem}>
                <NumberOfTalbeFramePackagesRow></NumberOfTalbeFramePackagesRow>
              </Grid>
            </>
          ) : (
            <Grid container spacing={3} className={classes.gridItem}>
              <NoTabelFrameWarning></NoTabelFrameWarning>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
});
