import {ControlUnit, Product, ProductType} from '../../api/api';
import product1Small from '../../assets/products/product-1/tableTopElectricWall175.png';
import product1Large from '../../assets/products/product-1/tableTopElectricWall350.png';
import product2Small from '../../assets/products/product-2/tableTopManualWall175.png';
import product2Large from '../../assets/products/product-2/tableTopManualWall350.png';
import product3Small from '../../assets/products/product-3/tableTopElectricFreeStanding175.png';
import product3Large from '../../assets/products/product-3/tableTopElectricFreeStanding350.png';
import product4Small from '../../assets/products/product-4/tableTopManualFreeStanding175.png';
import product4Large from '../../assets/products/product-4/tableTopManualFreeStanding350.png';
import product5Small from '../../assets/products/product-5/wallCabinet175.png';
import product5Large from '../../assets/products/product-5/wallCabinet350.png';
import {ProductSteps} from '../../view/pages/products/products-list';

export const productStepsToDefaultProduct = (product: ProductSteps) => {
  const {v4: uuidv4} = require('uuid');

  let temp = new Product();
  temp.guid = uuidv4();
  temp.name = product.name;
  temp.productType = product.type;
  temp.configurationParams = [];
  temp.controlUnit = ControlUnit.NotDefined;
  temp.configured = false;

  return temp;
};

export const getSmallImgByProductType = (type: ProductType) => {
  let img = '';

  switch (type) {
    case ProductType.Table1:
      img = product1Small;
      break;
    case ProductType.Table2:
      img = product2Small;
      break;
    case ProductType.Table3:
      img = product3Small;
      break;
    case ProductType.Table4:
      img = product4Small;
      break;
    case ProductType.WallCabinet1:
      img = product5Small;
      break;
    case ProductType.WallCabinet2:
      img = 'https://via.placeholder.com/57x57';
      break;

    default:
      break;
  }
  return img;
};
export const getLargeImgByProductType = (type: ProductType) => {
  let img = '';

  switch (type) {
    case ProductType.Table1:
      img = product1Large;
      break;
    case ProductType.Table2:
      img = product2Large;
      break;
    case ProductType.Table3:
      img = product3Large;
      break;
    case ProductType.Table4:
      img = product4Large;
      break;
    case ProductType.WallCabinet1:
      img = product5Large;
      break;
    case ProductType.WallCabinet2:
      img = 'https://via.placeholder.com/174x175';
      break;

    default:
      break;
  }

  return img;
};
